import axios from "axios";
import { convertToFormData } from "../../utils";
import { Attachment, AttachmentType } from "@sumit-platforms/types";

const AttachmentService = ({ config }: { config: any }) => {
  const attachmentsEndpoint =
    config.server.host + "/" + config.server.attachments;

  const getPublicUrl = async (attachmentId: number) => {
    try {
      return (
        await axios.get(`${attachmentsEndpoint}/${attachmentId}/getPublicUrl`)
      ).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const handleDownloadAttachment = async (attachmentId: number) => {
    const url = await getPublicUrl(attachmentId);
    window.open(url, "_blank");
  };

  const create = async (
    type: AttachmentType,
    idUpload: number[],
    idClient: number,
    attachment: File
  ): Promise<Attachment> => {
    try {
      const formData = convertToFormData({
        type,
        idUpload,
        idClient,
        attachment,
      });
      const response = await axios.post(attachmentsEndpoint, formData);
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const remove = async (id: number): Promise<void> => {
    try {
      axios.delete(`${attachmentsEndpoint}/${id}`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    getPublicUrl,
    handleDownloadAttachment,
    create,
    remove,
  };
};

export default AttachmentService;
