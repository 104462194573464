import React, { ReactElement, useMemo } from "react";
import { CircularProgress } from "@mui/material";
import prettyBytes from "pretty-bytes";

import { ActiveUploadTableRow, UploadProgress } from "@sumit-platforms/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleX } from "@fortawesome/pro-solid-svg-icons";
import { faCircle } from "@fortawesome/pro-light-svg-icons";
import { SucceedUploadRow } from "./UploadManager";
import { secondaryColor } from "../../bazar-theme";

export const UploadManagerItem = ({
  upload,
  progress,
  uploadInfoText,
}: {
  upload: ActiveUploadTableRow | SucceedUploadRow;
  progress: UploadProgress | null;
  uploadInfoText?: string;
}) => {
  const iconMap: Record<string, ReactElement> = useMemo(() => {
    return {
      uploading: (
        <CircularProgress
          className="progressIcon"
          variant="determinate"
          size={16}
          value={progress?.progress || 0}
        />
      ),
      success: (
        <FontAwesomeIcon
          className="succeedIcon"
          icon={faCircleCheck}
          style={{ color: "green" }}
        />
      ),
      convert: (
        <FontAwesomeIcon
          className="succeedIcon"
          icon={faCircleCheck}
          style={{ color: "green" }}
        />
      ),
      error: (
        <FontAwesomeIcon
          className="failIcon"
          icon={faCircleX}
          style={{ color: "red" }}
        />
      ),
      pending: (
        <FontAwesomeIcon
          className="pendingIcon"
          icon={faCircle}
          style={{ color: "gray" }}
        />
      ),
      attaching_files: (
        <CircularProgress
          className="progressIcon"
          size={16}
          value={100}
          style={{
            color: secondaryColor,
          }}
        />
      ),
    };
  }, [progress]);
  return (
    <div className="UploadManagerItem">
      <div className="icon">{iconMap[upload.status.toLowerCase()]}</div>
      <div className="name ellipsis">{upload.name}</div>
      <div className="infoText">
        {uploadInfoText && <span className="smallText">{uploadInfoText}</span>}
      </div>
    </div>
  );
};
