import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Alert, AlertTitle } from "@mui/material";
import Stack from "@mui/material/Stack";
import { EnchancedAlert, useAlert } from "../../store/alert";

import "./Toaster.scss";

export const Toaster = () => {
  const { alert } = useAlert();
  const toasterRef = useRef<EnchancedAlert[]>([]);
  const timeOutRef = useRef<NodeJS.Timeout>();
  const innerTimeOutRef = useRef<NodeJS.Timeout>();
  const [toaster, setToaster] = useState<EnchancedAlert[]>([]);

  useEffect(() => {
    timeOutRef?.current && clearTimeout(timeOutRef?.current);
    if (alert) {
      toasterRef.current = [alert, ...toasterRef.current];
      setToaster(toasterRef.current);
      timeOutRef.current = setTimeout(async () => {
        innerTimeOutRef?.current && clearTimeout(innerTimeOutRef?.current);
        await Promise.all(
          toasterRef.current.map(async (_a, i) => {
            return new Promise<void>((resolve) => {
              innerTimeOutRef.current = setTimeout(() => {
                toasterRef.current.pop();
                setToaster([...toasterRef.current]);
                resolve();
              }, i * 1000);
            });
          })
        );
      }, 3000);
    }
  }, [alert]);

  return (
    <Stack className="Toaster" spacing={2}>
      {toaster.map((t, i) => (
        <Alert key={i} {...t}>
          <AlertTitle>{t.title}</AlertTitle>
          {t.message}
        </Alert>
      ))}
    </Stack>
  );
};
