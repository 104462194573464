import React, { useState } from "react";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { Upload } from "@sumit-platforms/types";
import { Button } from "./../../core/Button/Button";

import "./UnmergeModal.scss";

export interface UnmergeModalProps {
  cancel: () => void;
  unmergeMedia: (idMedia: number) => void;
  upload: Upload;
}

export const UnmergeModal = ({
  cancel,
  unmergeMedia,
  upload,
}: UnmergeModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUnmergeMedia = async (idMedia: number) => {
    setIsLoading(true);
    await unmergeMedia(idMedia);
    setIsLoading(false);
  };

  return (
    <div className="UnmergeModal">
      <div className="modalName">{t("manage_media")}</div>
      <div className="formBody">
        <div className="upperBody">{t("manage_files_instructions")}</div>
        <div className="middelBody">
          {upload.media.map((m, i) => {
            return (
              <div key={i} className="selectedUpload">
                <div>{m.name}</div>
                <div
                  className="deSelectUpload"
                  onClick={() => handleUnmergeMedia(m.idMedia)}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </div>
              </div>
            );
          })}
        </div>
        <div className="lowerBody">
          <Button loading={isLoading} onClick={cancel} color="primary">
            {t("close")}
          </Button>
        </div>
      </div>
    </div>
  );
};
