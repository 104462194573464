import {
  BonusType,
  JobBonus,
  JobCommunityMembers,
} from "@sumit-platforms/types";
import { TableCell } from "../../core/TableCells";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { getPriceStringWithCurrency } from "../../utils/formatters";
import { getBonusPriceByType } from "../../utils/prices";

interface CommunityMembersTableCellProps {
  members?: JobCommunityMembers;
  prices?: { qc?: number; transcribe?: number };
  bonus?: Partial<JobBonus>;
}

export const CommunityMembersTableCell = ({
  members,
  prices,
  bonus,
}: CommunityMembersTableCellProps) => {
  const { t } = useTranslation();
  const bonusType = useMemo(() => (bonus ? bonus.bonusType : null), [bonus]);
  const transcriberText = useMemo(
    () =>
      members?.transcriber?.user
        ? `T - ${t(members?.transcriber?.method)} ${
            members?.transcriber?.user.firstName
          } ${members?.transcriber?.user.lastName}`
        : "-",
    [members]
  );
  const qcText = useMemo(
    () =>
      members?.qc?.user
        ? `Q - ${t(members?.qc?.method)} ${members?.qc?.user?.firstName} ${
            members?.qc?.user?.lastName
          }`
        : "-",
    [members]
  );

  const getPriceWithBonus = useCallback(
    (of: "qc" | "transcribe") => {
      if (!prices || !prices[of]) return "";
      const priceText =
        getPriceStringWithCurrency({
          price: prices[of],
          currency: "ILS",
        }) || "";
      if (!bonus || !bonus[of]) return priceText;
      const bonusPrice = getBonusPriceByType({
        bonusPrice: bonus[of] as number,
        jobPrice: prices[of] as number,
        bonusType,
      });
      const bonusText = getPriceStringWithCurrency({
        price: bonusPrice,
        currency: "ILS",
      });
      return bonusText ? `${priceText} + ${bonusText}` : priceText;
    },
    [bonus, prices, bonusType]
  );

  const transcribePrice = useMemo(() => {
    return getPriceWithBonus("transcribe");
  }, [getPriceWithBonus]);

  const qcPrice = useMemo(() => {
    return getPriceWithBonus("qc");
  }, [getPriceWithBonus]);

  return (
    <TableCell
      primaryText={transcriberText}
      primarySmallText={`${transcribePrice ? `(${transcribePrice})` : ""}`}
      secondaryText={qcText}
      secondarySmallText={`${qcPrice ? `(${qcPrice})` : ""}`}
    />
  );
};
