import { Align, TextDecoration } from "@sumit-platforms/types";

export const docxSpeakersDefaultSettings = {
  general: {
    presetName: "כללי",
    format: "audio",
    timeOffset: "00:00:00",
    interval: 0.5,
    rowCount: false,
    font: { fontFamily: "David", fontSize: 12, align: Align.justify },
  },
  images: { images: { align: Align.left, isActive: false } },
  notes: {
    notes: {
      isActive: true,
      font: {
        color: "#000000",
        textDecoration: ["underline", "italic"],
        fontSize: 12,
        align: Align.right,
      },
    },
  },
  subjects: {
    subjects: { isActive: true },
    sign: "hebrew",
    font: {
      color: "#000000",
      fontSize: 13,
      textDecoration: ["bold", "underline"],
      align: Align.justify,
    },
  },
  speakers: {
    newLine: false,
    indentation: false,
    showTitle: false,
    timing: false,
    interviewerInBold: false,
    markedSpeakers: ["מראיין", "מראיינת", "interviewer"],
    font: {
      color: "#000000",
      fontSize: 12,
      textDecoration: ["bold", "underline"],
      align: Align.justify,
    },
  },
  focusedDocument: {
    focusedDocument: {
      subjects: false,
      decisions: false,
      isActive: false,
      tasks: false,
    },
  },
  decisions: {
    gatherDecisions: {
      isActive: false,
      font: {
        color: "#000000",
        textDecoration: [],
        fontSize: 16,
        align: Align.center,
      },
    },
    decisions: {
      numbering: false,
      viewType: "c",
      numbring: false,
      isActive: true,
      font: {
        color: "#211e1e",
        fontSize: 12,
        textDecoration: ["underline", "bold"],
        align: Align.center,
      },
    },
    votes: {
      attendeesVotes: false,
      format: "list",
      isActive: false,
      font: { color: "#0e0d0d", textDecoration: [], fontSize: 16 },
    },
  },
  information: {
    jobName: {
      color: "#000000",
      textDecoration: ["bold"],
      fontSize: 12,
      isActive: true,
      align: Align.center,
    },
    attendees: { isActive: true },
    showTitle: false,
    format: "list",
    jobDate: {
      color: "#000000",
      calendars: ["hebrew", "regular"],
      textDecoration: [],
      fontSize: 12,
      align: Align.center,
      isActive: true,
    },
    showRoleType: false,
  },
  headerFooter: {
    otherPagesHeader: {
      jobName: { isActive: true, align: Align.right },
      jobDate: { isActive: false, align: Align.center },
      logo: {
        file: "",
        size: 210,
        align: Align.left,
        isActive: false,
        url: "https://firebasestorage.googleapis.com/v0/b/sumit-249611.appspot.com/o/export-config%2Fdefault%2F%D7%9C%D7%95%D7%92%D7%95%20%D7%98%D7%A7%D7%A1%D7%98%D7%95%D7%90%D7%9C%D7%99_copy_bokmhqfyl.png?alt=media&token=90edd9a9-01ee-4835-8d92-3925ceba2f7b",
      },
      isActive: true,
    },
    footer: {
      pageNumbering: { isActive: true, align: Align.right },
      footerText: " נוצר על ידי   ",
      logo: {
        file: "",
        size: 50,
        align: Align.left,
        isActive: true,
        url: "https://firebasestorage.googleapis.com/v0/b/sumit-dev-0.appspot.com/o/Logo%201B.png?alt=media&token=32fafd00-03f5-467c-8dd6-81595b283bbe",
      },
      isActive: true,
    },
    firstPageHeader: {
      jobName: { isActive: false, align: Align.center },
      jobDate: { align: Align.center, isActive: false },
      logo: {
        file: "",
        size: 250,
        isActive: false,
        align: Align.center,
        url: "https://firebasestorage.googleapis.com/v0/b/sumit-249611.appspot.com/o/export-config%2Fdefault%2F%D7%9C%D7%95%D7%92%D7%95%20%D7%98%D7%A7%D7%A1%D7%98%D7%95%D7%90%D7%9C%D7%99.png?alt=media&token=f88b3603-4c7f-452d-ad92-eb568f372919",
      },
      isActive: true,
    },
  },
  tasks: {
    gatherTasks: {
      isActive: false,
      font: {
        color: "#101011",
        textDecoration: [],
        fontSize: 16,
        align: Align.left,
      },
    },
    tasks: {
      isActive: false,
      font: {
        color: "#2b2525",
        textDecoration: [],
        fontSize: 16,
        align: Align.left,
      },
    },
  },
  remarks: {
    startEnd: {
      text1: "פ ר ו ט ו ק ו ל",
      text2: "תום הדיון",
      isActive: true,
      font: {
        color: "#382827",
        fontSize: 14,
        textDecoration: ["bold"],
        align: Align.center,
      },
    },
    signature: {
      names: ['יו"ר הוועדה', "מנהל/ת הוועדה"],
      font: { textDecoration: ["bold"], fontSize: 12 },
    },
    greeting: {
      text1: "בברכה,",
      isActive: true,
      font: {
        color: "#000000",
        textDecoration: [],
        fontSize: 14,
        align: Align.center,
      },
    },
  },
  tableOfSubjects: {
    newPage: false,
    sign: "brackets",
    tableOfSubjects: { isActive: true },
    font: {
      color: "#000000",
      textDecoration: ["bold"],
      fontSize: 14,
      align: Align.right,
    },
  },
};

export const docxTableDefaultSettings = {
  general: {
    presetName: "Default",
    font: {
      fontFamily: "Arial",
      fontSize: 14,
      align: Align.justify,
    },
    letterSpacing: 1.2,
    rowsSpacing: 1.5,
    paragraphSpacing: 1,
  },
  speakers: {
    showName: true,
    font: {
      fontSize: 14,
      color: "#000000",
      textDecoration: [],
    },
    interviewerInBold: false,
    markedSpeakers: ["מראיין", "מראיינת", "interviewer"],
  },
  intervalsAndTiming: {
    intervals: 30,
    timecode: "00:00:00",
  },
  headerFooter: {
    firstPageHeader: {
      isActive: true,
      logo: {
        isActive: false,
        align: Align.center,
        size: 180,
        url: "",
        file: "",
      },
      jobName: {
        isActive: true,
        align: Align.center,
      },
      jobDate: {
        isActive: false,
        align: Align.center,
      },
    },
    otherPagesHeader: {
      isActive: true,
      logo: {
        isActive: false,
        align: Align.center,
        size: 180,
        url: "",
        file: "",
      },
      jobName: {
        isActive: true,
        align: Align.center,
      },
      jobDate: {
        isActive: false,
        align: Align.center,
      },
    },
    footer: {
      isActive: true,
      logo: {
        isActive: false,
        align: Align.center,
        size: 70,
        url: "",
        file: "",
      },
      pageNumbering: {
        isActive: true,
        align: Align.center,
        type: "page x",
      },
      footerText: "",
    },
  },
};
