import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { IconButton, Stack, SxProps, ThemeProvider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { SpinningLoader } from "../SpinningLoader/SpinningLoader";
import { bazarTheme } from "../../bazar-theme";
import { useTranslation } from "react-i18next";

import "./FileInput.scss";

export interface FileInputProps {
  onFileChange: (file: File) => Promise<any>;
  onDeleteFile: () => void;
  file?: File | string | null;
  allowedFormats?: string[];
  label: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  variant?: "contained" | "text" | "outlined";
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  color?:
    | "info"
    | "warning"
    | "inherit"
    | "secondary"
    | "primary"
    | "error"
    | "success";
  sx?: SxProps;
  btnStyle?: React.CSSProperties;
  toast?: {
    success: (message: string) => void;
    error: (message: string, err?: any) => void;
    info: (message: string) => void;
  };
}

export const FileInput = ({
  onFileChange,
  onDeleteFile,
  file = null,
  label = "Browse",
  allowedFormats = [],
  disabled,
  loading,
  className,
  variant = "contained",
  startIcon,
  endIcon,
  color,
  sx,
  btnStyle,
  toast,
}: FileInputProps) => {
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState<any>(file);

  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setUploadedFile(file);
  }, [file]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setUploading(true);
      const reader = new FileReader();
      reader.onloadend = async (e) => {
        if (e.target && typeof e.target.result === "string") {
          try {
            await onFileChange(file);
            toast?.success && toast?.success(t("file_upload_success"));
          } catch (e) {
            console.error(e);
            setUploadedFile(false);
            toast?.error && toast?.error(t("file_upload_failed"));
          }
          setUploading(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteFile = async () => {
    if (onDeleteFile) {
      try {
        await onDeleteFile();
        toast?.success && toast?.success(t("file_delete_success"));
      } catch (e) {
        console.error(e);
        setUploadedFile(false);
        toast?.error && toast?.error(t("file_delete_failed"));
      }
    }
    setUploadedFile(false);
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <div className="FileInput">
        {uploadedFile?.name ? (
          <>
            <p className="filename ellipsis">
              {uploadedFile && uploadedFile.name}
            </p>
            <div className={"delete-wrapper"}>
              {loading && <SpinningLoader className={"delete-spinner"} />}
              <IconButton
                type="button"
                style={{ height: 30, width: 30 }}
                onClick={handleDeleteFile}
                disabled={loading || uploading}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ height: 18, width: 18 }}
                />
              </IconButton>
            </div>
          </>
        ) : (
          <label htmlFor="file-input">
            <MuiLoadingButton
              variant={variant}
              component="span"
              className={classNames("BazarButton", className)}
              disabled={disabled || loading}
              loading={loading || uploading}
              startIcon={startIcon}
              endIcon={endIcon}
              color={color}
              sx={sx}
              style={btnStyle}
            >
              {label}
              <input
                hidden
                id="file-input"
                className={"fileInput"}
                type="file"
                onChange={handleFileChange}
                accept={allowedFormats.join(",")}
              />
            </MuiLoadingButton>
          </label>
        )}
      </div>
    </ThemeProvider>
  );
};
