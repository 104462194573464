interface Attachment {
  idAttachment: number;
  url: string;
  type: AttachmentType;
  path: string;
  name: string;
  mimetype: string;
}

export enum AttachmentType {
  NOTE = "NOTE",
  JOB_SOURCE_DATA = "JOB_SOURCE_DATA",
  SCRIPT = "SCRIPT",
}

export type { Attachment };
