import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Slider, TextField, ThemeProvider } from "@mui/material";
import { ChangeMeta, NumberFormatBase } from "react-number-format";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark } from "@fortawesome/pro-light-svg-icons";

import { bazarTheme } from "../../../bazar-theme";
import "./SliderFilter.scss";

export interface SliderFilterProps {
  value: number[];
  setValue?: (value: number[]) => void;
  title?: string;
  min?: number;
  max?: number;
  format?: (value: number) => string;
}

function caretUnknownFormatBoundary(formattedValue: string) {
  const boundaryAry = Array.from({ length: formattedValue.length + 1 }).map(
    () => true
  );

  for (let i = 0, ln = boundaryAry.length; i < ln; i++) {
    // consider caret to be in boundary if it is before or after numeric value
    boundaryAry[i] = Boolean(
      formattedValue.match(/\d/) || formattedValue.match(/\d/)
    );
  }

  return boundaryAry;
}

export const SliderFilter = ({
  value,
  setValue = (value: number[]) => _.noop(value),
  title = "",
  min = 0,
  max = 0,
  format,
}: SliderFilterProps) => {
  const [uiValue, setUiValue] = useState<number[] | number>(value);

  const isReset = useMemo(
    () => value?.length === 2 && value[0] === min && value[1] === max,
    [value, max, min]
  );

  const reset = () => {
    //TODO: Handle reset buttons behavior for all other filters
    if (isReset) return;
    setValue([min, max]);
    setUiValue([min, max]);
  };

  const handleChange = (
    event: React.SyntheticEvent | Event,
    newValue: number[] | number
  ) => {
    setValue(newValue as number[]);
    setUiValue(newValue as number[]);
  };

  const handleChangeMinInput = (min: string) => {
    if (!_.isNumber(parseInt(min))) return;
    setValue([parseInt(min), value[1]]);
    setUiValue([parseInt(min), value[1]]);
  };

  const handleChangeMaxInput = (max: string) => {
    if (!_.isNumber(parseInt(max))) return;
    setValue([value[0], parseInt(max)]);
    setUiValue([value[0], parseInt(max)]);
  };

  const handleSliderFormat = (value: number) => {
    return format ? format(value) : JSON.stringify(value);
  };

  const handleInputsFormat = (value: string) => {
    return format ? format(parseInt(value)) : value;
  };

  const handleUiValueChange = (event: Event, newValue: number | number[]) => {
    setUiValue(newValue);
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <div className="BazarSliderFilter">
        <div className="titleWrapper">
          <span>{title}</span>
          <FontAwesomeIcon
            icon={faFilterCircleXmark}
            onClick={reset}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="sliderWrapper">
          <Slider
            min={min}
            max={max}
            value={uiValue}
            onChangeCommitted={handleChange}
            onChange={handleUiValueChange}
            valueLabelDisplay="auto"
            color="secondary"
            valueLabelFormat={handleSliderFormat}
          />
        </div>
        <div className="inputsWrapper">
          <NumberFormatBase
            disabled={true}
            value={(uiValue as number[])[0]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeMinInput(e.target.value)
            }
            customInput={TextField}
            variant="outlined"
            format={handleInputsFormat}
            removeFormatting={function (
              inputValue: string,
              changeMeta?: ChangeMeta | undefined
            ): string {
              return handleInputsFormat(inputValue);
            }}
            getCaretBoundary={caretUnknownFormatBoundary}
          />

          <NumberFormatBase
            disabled={true}
            value={(uiValue as number[])[1]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeMaxInput(e.target.value)
            }
            customInput={TextField}
            variant="outlined"
            format={handleInputsFormat}
            removeFormatting={function (
              inputValue: string,
              changeMeta?: ChangeMeta | undefined
            ): string {
              return handleInputsFormat(inputValue);
            }}
            getCaretBoundary={caretUnknownFormatBoundary}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};
