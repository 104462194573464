import { AuthMapItem, UserRole, UserStatus } from "@sumit-platforms/types";

export const isAuthorized = ({
  authConfig,
  userRoles,
  userStatus,
}: {
  authConfig?: AuthMapItem;
  userRoles?: UserRole[];
  userStatus?: UserStatus;
}): boolean => {
  if (!authConfig) return true;
  if (!userRoles || !userStatus) return false;
  const isRoleAllowed =
    !authConfig.roles ||
    userRoles.some((role) => authConfig.roles?.includes(role));

  const isStatusAllowed =
    !userStatus ||
    !authConfig.status ||
    authConfig.status.some((status) => status.includes(userStatus));

  return isRoleAllowed && isStatusAllowed;
};
