import React, { FC } from "react";
import { ResetPasswordForm } from "@sumit-platforms/ui-bazar";

import authService from "../../services/authService";

import "./Auth.scss";

const ResetPasswordPage: FC = (): JSX.Element => {
  return (
    <div className="ResetPasswordPage">
      <ResetPasswordForm authService={authService} />
    </div>
  );
};

export default ResetPasswordPage;
