import { Languages } from "../../constants/languages";

enum SupportedImportSubtitleFormats {
  SRT = "srt",
}

type ImportSubtitlesPayload = {
  subtitleDownloadUrl: string;
  format: SupportedImportSubtitleFormats;
  language: Languages;
};

enum SupportedExportSubtitleFormats {
  MARKERS = "markers",
  LOCATORS = "locators", // same as markers
}

type ExportSubtitlesPayload = {
  jobDownloadUrl: string;
  format: SupportedExportSubtitleFormats;
  tcOffsets?: number[][];
  autoBreak?: boolean;
};

export { SupportedImportSubtitleFormats, SupportedExportSubtitleFormats };
export type { ImportSubtitlesPayload, ExportSubtitlesPayload };
