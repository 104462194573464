import _ from "lodash";
import React from "react";
import {
  InputLabel,
  Stack,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as ToggleButtonGroupMuiProps,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { bazarTheme } from "../../bazar-theme";
import { InfoTooltipIcon } from "../InfoTooltipIcon/InfoTooltipIcon";

import "./ToggleButtonGroup.scss";

export interface ButtonGroupOption {
  value: any;
  label: string | IconDefinition;
  disabled?: boolean;
  infoTooltip?: string;
  hide?: boolean;
}
export interface ToggleButtonGroupProps extends ToggleButtonGroupMuiProps {
  buttons: ButtonGroupOption[];
  exclusive?: boolean;
  setValue?: (value: any) => void;
  label?: string;
  labelId?: string;
  spacing?: number;
}

export const ToggleButtonGroup = ({
  buttons,
  setValue,
  exclusive = true,
  label,
  labelId,
  spacing,
  ...props
}: ToggleButtonGroupProps) => {
  const control: Partial<ToggleButtonGroupProps> = {
    value:
      !exclusive && !_.isArray(props.value)
        ? []
        : _.isNil(props.value)
        ? props.defaultValue
        : props.value,
    onChange: props.onChange,
    disabled: props.disabled,
  };

  if (exclusive) {
    control["exclusive"] = true;
  }
  return (
    <ThemeProvider theme={bazarTheme}>
      <Stack
        spacing={spacing || 0}
        alignItems="stretch"
        className="BazarToggleButtonGroup"
      >
        {label && (
          <InputLabel shrink htmlFor={labelId}>
            {label}
          </InputLabel>
        )}
        <MuiToggleButtonGroup {...control}>
          {buttons.map((b) =>
            b.hide ? null : (
              <ToggleButton
                className={"buttonGroup"}
                value={b.value}
                key={b.value}
                disabled={b.disabled}
              >
                {typeof b.label === "string" ? (
                  b.infoTooltip ? (
                    <InfoTooltipIcon tooltipText={b.infoTooltip}>
                      <p className={"buttonOption"}>{b.label}</p>
                    </InfoTooltipIcon>
                  ) : (
                    <p className={"buttonOption"}>{b.label}</p>
                  )
                ) : (
                  <FontAwesomeIcon icon={b.label} />
                )}
              </ToggleButton>
            )
          )}
        </MuiToggleButtonGroup>
      </Stack>
    </ThemeProvider>
  );
};
