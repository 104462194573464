import _ from "lodash";
import { InvoiceStatus, JobStatus, UserStatus } from "@sumit-platforms/types";
import {
  getInvoiceStatusVisual,
  getMustStatusVisual,
  getOperaStatusVisual,
  getUserStatusVisual,
} from "../../utils/helpers";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Tooltip } from "@mui/material";
import { ContextMenu, ContextOption, TableCellToolTip } from "../../../index";

import "./StatusCell.scss";

interface StatusCellProps {
  status: JobStatus | UserStatus | InvoiceStatus;
  type: "user" | "job" | "invoice" | "client";
  progress?: number;
  disabled?: boolean;
  dropDownOptions?: ContextOption[];
  hasReviewer?: boolean;
  isTrainingUser?: boolean;
  toolTip?: TableCellToolTip;
}

export function StatusCell({
  status,
  type,
  progress,
  dropDownOptions,
  disabled,
  isTrainingUser,
  toolTip,
}: StatusCellProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { statusColor, statusTitle } = useMemo(() => {
    if (type === "user") {
      return getUserStatusVisual(status as UserStatus);
    }
    if (type === "job") {
      return getOperaStatusVisual({
        status: status as JobStatus,
        isTrainingUser,
      });
    }
    if (type === "invoice") {
      return getInvoiceStatusVisual(status as InvoiceStatus);
    }
    if (type === "client") {
      const statusData = getMustStatusVisual(status as JobStatus);
      return statusData;
    }
    return { statusColor: "", statusTitle: "" };
  }, [isTrainingUser, status, type]);

  const onCellClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      className="StatusCell"
      style={{
        backgroundColor: statusColor,
        userSelect: "none",
        pointerEvents: dropDownOptions?.length || toolTip ? "auto" : "none",
        cursor: dropDownOptions?.length ? "pointer" : "default",
      }}
      onClick={(e: any) => {
        if (disabled) return;
        e.preventDefault();
        e.stopPropagation();
        onCellClick(e);
        setIsOpen(!isOpen);
      }}
    >
      {!_.isEmpty(dropDownOptions) && (
        <ContextMenu
          onClose={handleClose}
          anchorEl={anchorEl}
          open={isOpen}
          setIsOpen={setIsOpen}
          context={dropDownOptions || []}
        />
      )}
      {t(statusTitle)}
      {progress && [JobStatus.stt].includes(status as JobStatus) ? (
        <span className="progress">{`(${progress}%)`}</span>
      ) : null}
      {toolTip && (
        <Box px={0.8}>
          <Tooltip placement="bottom-start" title={toolTip.content}>
            <span style={{ color: toolTip.color }}>{toolTip.icon}</span>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
