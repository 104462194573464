import React, { useMemo } from "react";
import {
  ThemeProvider,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  InputLabel,
} from "@mui/material";
import { bazarTheme } from "../../bazar-theme";
import classNames from "classnames";

export interface SwitchProps extends MuiSwitchProps {
  setValue?: (value: boolean) => void;
  label?: string;
  labelStyle?: React.CSSProperties;
}

export const Switch = ({
  setValue,
  label,
  labelStyle,
  ...props
}: SwitchProps) => {
  const id = useMemo(() => {
    return label ? `switch-${label}` : "";
  }, []);
  return (
    <ThemeProvider theme={bazarTheme}>
      <>
        {label ? (
          <InputLabel
            htmlFor={id}
            className={classNames({
              disabled: props.disabled,
            })}
            sx={{ cursor: "pointer", ...labelStyle }}
          >
            {label}:
          </InputLabel>
        ) : null}
        <MuiSwitch
          onChange={(e) => {
            setValue && setValue(e.target.checked);
          }}
          id={id}
          {...props}
        />
      </>
    </ThemeProvider>
  );
};
