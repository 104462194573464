import { MulterFileInfo } from "../common/fileinfo";
import { JobTranscriptionSource } from "./Job";

enum EditorType {
  SUBTITLES = "subtitles",
  SUBTITLES_TRANSLATION = "subtitles-translation",
  TRANSCRIPT = "transcript",
}
interface JobTypeOptions {
  key: string;
  hasSrcFile: boolean;
  hasBurn: boolean;
  editorMode:
    | "transcript"
    | "subtitles"
    | "subtitles-translation"
    | "translation";
  editor?: EditorType;
  allowAutomaticTranscription?: boolean;
  showSubtitles?: boolean;
  allowAligner?: boolean;
  allowRepresentative?: boolean;
  allowOoona?: boolean;
}

interface JobTypes {
  protocol: JobTypeOptions;
  // summary: JobTypeOptions;
  brief: JobTypeOptions;
  interview: JobTypeOptions;
  subtitles: JobTypeOptions;
  // subtitlesSync: JobTypeOptions;
  subtitlesTranslation: JobTypeOptions;
  // translation: JobTypeOptions;
  // textEnchancment: JobTypeOptions;
}

const jobTypesOptions: { [key: string]: JobTypeOptions } = {
  protocol: {
    key: "protocol",
    hasSrcFile: false,
    hasBurn: false,
    editorMode: EditorType.TRANSCRIPT,
    allowAutomaticTranscription: true,
    allowAligner: true,
    allowRepresentative: true,
  },
  // summary: {
  //   key: "summary",
  //   hasSrcFile: false,
  //   hasBurn: false,
  //   editorMode: EditorType.TRANSCRIPT,
  // },
  brief: {
    key: "brief",
    hasSrcFile: false,
    hasBurn: false,
    editorMode: EditorType.TRANSCRIPT,
    allowAutomaticTranscription: false,
    allowAligner: true,
    allowRepresentative: true,
  },
  interview: {
    key: "interview",
    hasSrcFile: false,
    hasBurn: false,
    editorMode: EditorType.TRANSCRIPT,
    allowAutomaticTranscription: true,
    allowAligner: true,
  },
  subtitles: {
    key: "subtitles",
    hasSrcFile: false,
    hasBurn: true,
    editorMode: EditorType.SUBTITLES,
    allowAutomaticTranscription: true,
    showSubtitles: true,
    allowAligner: true,
    allowOoona: true,
  },
  // subtitlesSync: {
  //   key: "subtitlesSync",
  //   hasSrcFile: true,
  //   hasBurn: true,
  //   editor: EditorType.SUBTITLES,
  // },
  subtitlesTranslation: {
    key: "subtitlesTranslation",
    hasSrcFile: true,
    hasBurn: false,
    editorMode: EditorType.SUBTITLES_TRANSLATION,
    showSubtitles: true,
  },
  // translation: {
  //   key: "translation",
  //   hasSrcFile: true,
  //   hasBurn: true,
  // },
  // textEnchancment: {
  //   key: "textEnchancment",
  //   hasSrcFile: true,
  //   hasBurn: true,
  //   editor: EditorType.SUBTITLES,
  // },
};

const jobTypes: Array<keyof JobTypes> = [
  "protocol",
  // "summary",
  "brief",
  "interview",
  "subtitles",
  // "subtitlesSync",
  // "subtitlesTranslation",
  // "translation",
  // "textEnchancment",
];

type JobTypeMeta = {
  typeName: keyof JobTypes;
  src?: string;
  burn?: boolean;
  autoTranscript?: boolean;
  transcriptionSource?: JobTranscriptionSource;
  representative?: boolean;
};

type JobCustomMetadataItem = {
  key: string;
  value: any;
};

type JobCustomMetadata = JobCustomMetadataItem[];

export { jobTypesOptions, jobTypes };
export type { JobTypes, JobTypeMeta, JobCustomMetadata, JobCustomMetadataItem };
