import React from "react";
import { ThemeProvider } from "@mui/material";
import { bazarTheme } from "../../bazar-theme";

import "./Textarea.scss";

export interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  placeholder: string;
  setValue?: (value: string) => void;
}

export const Textarea = ({
  placeholder,
  setValue,
  ...props
}: TextareaProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue && setValue(e.target.value);
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <textarea
        className="Textarea"
        placeholder={placeholder}
        onChange={handleOnChange}
        {...props}
      ></textarea>
    </ThemeProvider>
  );
};
