import { useMemo } from "react";

export const useDetectDevice = () => {
  const isMacOS = useMemo(() => {
    return (window.navigator as any)?.userAgentData?.platform === "macOS";
  }, []);

  return {
    isMacOS,
  };
};
