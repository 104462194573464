export const isNum = (val: string) => /^\d+$/.test(val);
export const tcRegex =
  /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d).(?:[0-9]\d\d)$/;
export const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

const hebrewRegex = /^[א-ת\s]+$/;

const englishRegex = /^[a-zA-Z\s]+$/;

export const languagesRegex = {
  he: hebrewRegex,
  en: englishRegex,
};
