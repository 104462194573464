interface Invoice {
  idInvoice: number;
  idClient: number;
  invoiceNumber: string;
  status: InvoiceStatus;
  fixedPrice?: number;
  deletedAt?: Date;
  jobsInvoices?: JobInvoice[];
}

enum InvoiceStatus {
  AWAITING = "AWAITING",
  PAID = "PAID",
  WRITE_OFF = "WRITE_OFF",
}

enum InvoiceUrgency {
  STANDARD = "STANDARD",
  URGENT = "URGENT",
  CRITICAL = "CRITICAL",
}

interface JobInvoice {
  idJobInvoice: number;
  idJob: string;
  idInvoice?: number;
  urgency: InvoiceUrgency;
  invoice?: Invoice;
  deletedAt?: Date;
}

export type { Invoice, JobInvoice };
export { InvoiceStatus, InvoiceUrgency };
