import _ from "lodash";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faX,
} from "@fortawesome/pro-light-svg-icons";

import {
  ActiveUpload,
  ActiveUploadTableRow,
  PendingUpload,
  Upload,
  UploadProgress,
  UploadStatus,
} from "@sumit-platforms/types";

import { UploadManagerItem } from "./UploadManagerItem";

import "./UploadManager.scss";

export interface SucceedUploadRow {
  id: number;
  name: string;
  status: UploadStatus;
}

export const UploadManager = ({
  pendingUploads,
  activeUploads,
  succeedUploads,
  clearSucceedUploads,
  progress,
  uploadInfoText,
}: {
  pendingUploads: PendingUpload[];
  activeUploads: ActiveUpload[];
  succeedUploads: Upload[];
  clearSucceedUploads: () => void;
  progress: { [index: string]: UploadProgress | null };
  uploadInfoText: { [index: string]: string };
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(!_.isEmpty(activeUploads));
  const [pendingUploadTableRows, setPendingUploadTableRows] = useState<
    ActiveUploadTableRow[]
  >([]);
  const [activeUploadTableRows, setActiveUploadTableRows] = useState<
    ActiveUploadTableRow[]
  >([]);
  const [succeedUploadRows, setSucceedUploadRows] = useState<
    SucceedUploadRow[]
  >([]);

  const createActiveUploadTableRow = (
    upload: ActiveUpload
  ): ActiveUploadTableRow => {
    return {
      id: upload.idUpload,
      name: upload.name,
      status: upload.status,
    };
  };

  const createSucceedUploadRow = (upload: Upload): SucceedUploadRow => {
    return {
      id: upload.idUpload,
      name: upload.name,
      status: upload.status,
    };
  };

  useEffect(() => {
    const _pendingUploadTableRows = pendingUploads.map((u) =>
      createActiveUploadTableRow(u)
    );
    setPendingUploadTableRows(_pendingUploadTableRows);
    if (!isOpen && pendingUploads.length) {
      setIsOpen(true);
    }
  }, [pendingUploads]);

  useEffect(() => {
    const _activeUploadTableRows = activeUploads.map((u) =>
      createActiveUploadTableRow(u)
    );
    setActiveUploadTableRows(_activeUploadTableRows);
    if (!isOpen && activeUploads.length) {
      setIsOpen(true);
    }
  }, [activeUploads]);

  useEffect(() => {
    const succeedUploadRows = succeedUploads.map((u) =>
      createSucceedUploadRow(u)
    );
    setSucceedUploadRows(succeedUploadRows);
  }, [succeedUploads]);

  const handleClose = () => {
    clearSucceedUploads();
  };

  const getTitle = () => {
    const uploadsByStatuses = _.groupBy(activeUploadTableRows, "status");
    let statusTitle = t("uploads");
    if (!_.isEmpty(uploadsByStatuses[UploadStatus.uploading])) {
      statusTitle = t("uploading_count_progress", {
        count: uploadsByStatuses[UploadStatus.uploading].length,
      });
    } else if (!_.isEmpty(succeedUploadRows)) {
      statusTitle = t("uploading_count_complete", {
        count: succeedUploadRows.length,
      });
    }
    return statusTitle;
  };

  return (
    <div className={classNames("UploadManager", { isOpen })}>
      <Box className="uploadManagerContainer">
        <div className="header" onClick={() => setIsOpen((prev) => !prev)}>
          <div className="title">{getTitle()}</div>
          <div className="actions">
            <FontAwesomeIcon
              className="toggleButton"
              icon={isOpen ? faChevronDown : faChevronUp}
            />
            <FontAwesomeIcon
              className="x toggleButton"
              icon={faX}
              onClick={handleClose}
            />
          </div>
        </div>
        <div className="body">
          {[
            ...activeUploadTableRows,
            ...pendingUploadTableRows,
            ...succeedUploadRows,
          ].map((u) => (
            <UploadManagerItem
              upload={u}
              progress={progress[u.id]}
              key={u.id}
              uploadInfoText={uploadInfoText[u.id]}
            />
          ))}
        </div>
      </Box>
    </div>
  );
};
