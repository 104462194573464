import classNames from "classnames";
import React, { HTMLAttributes } from "react";
import { useDropzone, Accept } from "react-dropzone";
import { Button } from "../Button/Button";

import "./DragAndDrop.scss";

export interface DragAndDropProps extends HTMLAttributes<HTMLInputElement> {
  handleOnDrop: (files: File[]) => void;
  accept?: Accept;
  label?: string;
  innerCallToAction?: {
    divideLabel: string;
    buttonLabel: string;
  };
}

export const DragAndDrop = ({
  innerCallToAction,
  label,
  handleOnDrop,
  accept,
}: DragAndDropProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleOnDrop,
    accept,
  });
  return (
    <div
      {...getRootProps()}
      className={classNames("BazarDragAndDrop", { isEnter: isDragActive })}
    >
      <div className="label">{label}</div>
      {innerCallToAction && (
        <>
          <div className="divideLabel">{innerCallToAction.divideLabel}</div>
          <Button>{innerCallToAction.buttonLabel}</Button>
        </>
      )}
      <input {...getInputProps()} type="file" multiple />
    </div>
  );
};
