import { create } from "zustand";
import { ModalType } from "../core/Modal/Modal";

export type ModalStore = {
  modalContent: JSX.Element[];
  modalType: ModalType;

  pushModalContent: (modal: JSX.Element) => void;
  popModalContent: () => void;
  clearModalContent: () => void;
  setModalContent: (modal: JSX.Element) => void;

  setModalType: (modalType: ModalType) => void;
};

export const useModal = create<ModalStore>((set) => ({
  modalContent: [],
  modalType: "info",
  pushModalContent: (modal: JSX.Element) => {
    set((state: ModalStore) => {
      return { ...state, modalContent: [...state.modalContent, modal] };
    });
  },
  popModalContent: () => {
    set((state: ModalStore) => {
      state.modalContent.pop();
      return { ...state };
    });
  },
  clearModalContent: () => {
    set((state: ModalStore) => {
      return { ...state, modalContent: [] };
    });
  },
  setModalContent: (modal: JSX.Element) => {
    set((state: ModalStore) => {
      return { ...state, modalContent: [modal] };
    });
  },
  setModalType: (modalType: ModalType) => {
    set((state: ModalStore) => {
      return { ...state, modalType };
    });
  },
}));
