import _ from "lodash";
import { addDays } from "date-fns";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, ButtonBase } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { DateFilter } from "../Filters/DateFilter/DateFilter";

import "./DateRange.scss";

export interface DateRangeProps {
  valueFrom: Date;
  valueTo: Date;
  onValueFromChange: (date: Date) => void;
  onValueToChange: (date: Date) => void;
  title?: string;
  resetValue?: Date;
  useDateShortcuts?: boolean;
}

export const DateRange = ({
  valueFrom,
  valueTo,
  onValueFromChange,
  onValueToChange,
  title,
  resetValue,
  useDateShortcuts = false,
}: DateRangeProps) => {
  const { t } = useTranslation();
  const [method, setMethod] = useState<"today" | "tommorow" | "custom">(
    "custom"
  );

  const reset = () => {
    if (!resetValue) return;
    onValueFromChange(resetValue);
    onValueToChange(resetValue);
    setMethod("custom");
  };

  const setDateAuto = (start?: number, end?: number) => {
    let fromDate = new Date();
    let toDate = new Date();

    fromDate.setHours(0);
    fromDate.setMinutes(0);
    toDate.setHours(23);
    toDate.setMinutes(59);

    if (!_.isUndefined(start)) fromDate = addDays(fromDate, start);

    if (!_.isUndefined(end)) toDate = addDays(toDate, end);

    if (fromDate >= toDate) return;
    onValueFromChange(fromDate);
    onValueToChange(toDate);
  };

  return (
    <Box className={"DateRange"}>
      <Box className="titleWrapper" mb={1} mt={1}>
        <span>{title && `${title}:`}</span>
        {!_.isUndefined(resetValue) && (
          <FontAwesomeIcon
            icon={faFilterCircleXmark}
            onClick={reset}
            style={{ cursor: "pointer" }}
          />
        )}
      </Box>
      <Box display={"flex"}>
        {useDateShortcuts ? (
          <Box className={"specialMethods dateRangeDivider"}>
            <ButtonBase
              className={classNames({ active: method === "today" })}
              onClick={() => {
                setDateAuto();
                setMethod("today");
              }}
            >
              {t("today")}
            </ButtonBase>
            <ButtonBase
              className={classNames({ active: method === "tommorow" })}
              onClick={() => {
                setDateAuto(1, 1);
                setMethod("tommorow");
              }}
            >
              {t("tommorow")}
            </ButtonBase>
            <ButtonBase
              className={classNames({ active: method === "custom" })}
              onClick={() => {
                setMethod("custom");
              }}
            >
              {t("custom")}
            </ButtonBase>
          </Box>
        ) : (
          <Box className={"noneSpecialMethods"}></Box>
        )}

        <Box className="dateRangeDivider">
          <DateFilter
            value={valueFrom}
            setValue={(date: Date) => {
              onValueFromChange(date);
              setMethod("custom");
            }}
            maxDate={valueTo}
            useHour={true}
          />
        </Box>
        <Box className="dateRangeDivider">
          <DateFilter
            value={valueTo}
            setValue={(date: Date) => {
              onValueToChange(date);
              setMethod("custom");
            }}
            minDate={valueFrom}
            useHour={true}
          />
        </Box>
      </Box>
    </Box>
  );
};
