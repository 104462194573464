import React, { FC, useState, useEffect } from "react";
import _ from "lodash";
import classNames from "classnames";

import { Button } from "../../..";

import "../Steps/Steps.scss";

interface StepsButtonsProps {
  steps: {
    label: string;
  }[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
  done: () => void;
  backText: string;
  nextText: string;
  doneText: string;
  disabled: boolean;
  className?: string;
  loading?: boolean;
}

export const StepsButtons = ({
  steps,
  currentStep,
  setCurrentStep,
  done,
  backText,
  nextText,
  doneText,
  disabled,
  className,
  loading,
}: StepsButtonsProps): JSX.Element => {
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);

  useEffect(() => {
    const isFirst = currentStep === 0;
    setIsFirstStep(isFirst);
    const isLast = currentStep === steps.length - 1;
    setIsLastStep(isLast);
  }, [currentStep, steps.length]);

  return (
    <div className={classNames("StepsButtons", className)}>
      <Button
        disabled={isFirstStep}
        onClick={() => setCurrentStep(currentStep - 1)}
        color="primary"
      >
        {backText}
      </Button>
      <Button
        disabled={disabled}
        onClick={isLastStep ? done : () => setCurrentStep(currentStep + 1)}
        variant="outlined"
        loading={loading}
      >
        {isLastStep ? doneText : nextText}
      </Button>
    </div>
  );
};

export default StepsButtons;
