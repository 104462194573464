import { SupportedImportSubtitleFormats } from "../ExportImport/api/payloads";

export const imageMimeTypes = ["image/png", "image/gif", "image/jpeg", ".svg"];

export const videoMimeTypes = [
  "video/x-flv",
  "video/mp4",
  "application/x-mpegURL",
  "video/MP2T",
  "video/3gpp",
  "video/quicktime",
  "video/x-msvideo",
  "video/x-ms-wmv",
];
export const videoMimeTypesAccept = videoMimeTypes.reduce(
  (acc: any, mimeType: string) => {
    const suffix: any = [];
    return (acc[mimeType] = suffix), acc;
  },
  {}
);

export const audioMimeTypes = [
  "audio/32kadpcm",
  "audio/3gpp",
  "audio/3gpp2",
  "audio/aac",
  "audio/wav",
  "audio/mp4",
  "audio/MP4A-LATM",
  "audio/mpeg",
  "audio/mpeg4-generic",
  "audio/ogg",
  "audio/opus",
  "audio/m4a",
];
export const audioMimeTypesAccept = audioMimeTypes.reduce(
  (acc: any, mimeType: string) => {
    const suffix: any = [];
    if (mimeType === "video/x-ms-wmv") suffix.push(".wmv");
    return (acc[mimeType] = suffix), acc;
  },
  {}
);

export const applicationMimeType = ["application/octet-stream"];
export const applicationMimeTypesAccept = applicationMimeType.reduce(
  (acc: any, mimeType: string) => {
    const suffix: any = [];
    if (mimeType === "application/octet-stream") suffix.push(".aaf");
    return (acc[mimeType] = suffix), acc;
  },
  {}
);
export const applicationExtentions = [".aaf"];

export const attachmentsMimeTypes = [
  "application/pdf",
  "application/xml",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/plain",
  "text/x-csv",
  "application/vnd.ms-excel",
  "application/csv",
  "application/x-csv",
  "text/csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values",
  "application/x-subrip",
  "text/x-srt",
];

export const jobSourceDataTypes = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/x-subrip",
  "text/x-srt",
];

export const allowedImportSubtitles = Object.values(
  SupportedImportSubtitleFormats
).map((format) => (format.startsWith(".") ? format : `.${format}`));

export const mediaMimeTypes = [...audioMimeTypes, ...videoMimeTypes];

export const allowUploadMimeTypes = [
  ...audioMimeTypes,
  ...videoMimeTypes,
  ...applicationMimeType,
];
export const allowUploadTypes = [
  ...audioMimeTypes,
  ...videoMimeTypes,
  ...applicationMimeType,
  ...applicationExtentions,
];

export const validWriteFormats = [
  "wav",
  "mp3",
  "flac",
  "opus",
  "ogg",
  "flac",
  "amr",
  "mxf",
  "mp4",
  "mpeg",
  "avi",
  "flv",
  "webm",
  "mxf",
];

export const defaultVideoWriteFormat = "mp4";
export const defaultAudioWriteFormat = "mp3";

export const validMediaMergeFormats = ["wav", "mp3"];

export const fileInputAccept = (mimeTypes: string[]): string =>
  mimeTypes.join(",");
