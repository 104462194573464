import React, { useEffect, useState } from "react";
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { t } from "i18next";
import Typography from "@mui/material/Typography";
import { Indicator } from "../Indicator/Indicator";

import "./List.scss";

export interface ListItem {
  name: string;
  item: any;
  actions?: {
    icon: IconDefinition;
    onClick: (item: ListItem) => void;
  }[];
  indicator?: boolean;
  tooltip?: string;
}

export interface ListProps {
  items: ListItem[];
  onDelete: (item: ListItem) => void;
  loadedLabel?: string;
  searchLabel?: string;
}

export const List = ({ items, onDelete }: ListProps) => {
  const [filteredItems, setFilteredItems] = useState<ListItem[]>(items);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  return (
    <div className="BazarList">
      <div className="listWrapper">
        <MuiList>
          {filteredItems?.map((item) => (
            <ListItem
              key={`${item.name}-${item.indicator}`}
              sx={{ padding: 0 }}
            >
              <ListItemText>
                <Typography sx={{ display: "inline", width: "fit-content" }}>
                  {item.name}
                  {item.indicator && (
                    <Indicator
                      tooltip={{
                        title: item.tooltip ? (t(item.tooltip) as string) : "",
                      }}
                    />
                  )}
                </Typography>
              </ListItemText>
              {item.actions &&
                item.actions.map((action) => (
                  <ListItemIcon
                    onClick={(e: any) => {
                      action.onClick(item);
                    }}
                    style={{ cursor: "pointer", minWidth: 30 }}
                  >
                    <FontAwesomeIcon icon={action.icon} color={"#8593AA"} />
                  </ListItemIcon>
                ))}
              <ListItemIcon
                onClick={(e: any) => {
                  onDelete(item);
                }}
                style={{ cursor: "pointer", minWidth: 30 }}
              >
                <FontAwesomeIcon icon={faTrash} color={"#8593AA"} />
              </ListItemIcon>
            </ListItem>
          ))}
        </MuiList>
      </div>
    </div>
  );
};
