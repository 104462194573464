import { MAX_GAP_FOR_MERGE, VIDEO_FORMATS } from "../constants/globals";

export const loadMedia = (file: File): Promise<{ duration: number } | null> =>
  new Promise((resolve, reject) => {
    try {
      const media = document.createElement("video");
      media.preload = "metadata";
      media.onloadedmetadata = function () {
        media.remove();
        resolve(media);
      };
      media.onerror = function () {
        resolve(null);
      };
      media.src = window.URL.createObjectURL(file);
    } catch (e) {
      resolve(null);
    }
  });

export const checkMediaGap = (lengths: number[]): boolean =>
  Math.max(...lengths) - Math.min(...lengths) < MAX_GAP_FOR_MERGE;

export const isVideoFormat = (format: string) => VIDEO_FORMATS.includes(format);
