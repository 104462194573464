import { ShortcutType } from "@sumit-platforms/types";
import {
  faCirclePlay,
  faGlobe,
  faCompass,
  faPencil,
  faTimer,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Box } from "@mui/material";

export const KeyboardShortcutTitle = ({ type }: { type: string }) => {
  const { t } = useTranslation();

  const groupIcon: Record<ShortcutType, IconProp> = useMemo(() => {
    return {
      [ShortcutType.PLAYER]: faCirclePlay,
      [ShortcutType.EDITING]: faPencil,
      [ShortcutType.TIMING]: faTimer,
      [ShortcutType.NAVIGATION]: faCompass,
      [ShortcutType.GLOBAL]: faGlobe,
    };
  }, []);

  return (
    <Box className={"shortcutTitle"} pb={1}>
      <FontAwesomeIcon icon={groupIcon[type as ShortcutType]} />
      <p className={"titleText"}>{t(type.toLowerCase())}</p>
    </Box>
  );
};
