import _ from "lodash";
import {
  JobRange,
  RangeValidationResults,
  ReadSpeed,
} from "@sumit-platforms/types";

/* --- Time Validations ---  */

export const isOverlapping = (ranges: JobRange[], options: boolean) => {
  if (ranges.length <= 1) return;

  for (const [i, range] of ranges.entries()) {
    const prevRange = i > 0 ? ranges[i - 1] : null;
    const nextRange = i !== ranges.length - 1 ? ranges[i + 1] : null;

    const overlapping_prev = prevRange
      ? _.round(range.st, 2) < _.round(prevRange.et, 2)
      : !!range.validation?.errors?.overlapping_prev;
    const overlapping_next = nextRange
      ? _.round(range.et, 2) > _.round(nextRange.st, 2)
      : !!range.validation?.errors?.overlapping_next;

    setValidation(range, {
      errors: {
        overlapping_prev: overlapping_prev ? options : false,
        overlapping_next: overlapping_next ? options : false,
      },
    });
  }

  return ranges;
};

export const isStartTimeOverlapping = (
  ranges: JobRange[],
  options: boolean
) => {
  if (ranges.length <= 1) return;

  for (const [i, range] of ranges.entries()) {
    const prevRange = i > 0 ? ranges[i - 1] : null;
    const nextRange = i !== ranges.length - 1 ? ranges[i + 1] : null;

    const overlapping_start_prev = prevRange
      ? _.round(range.st, 2) < _.round(prevRange.st, 2)
      : !!range.validation?.errors?.overlapping_start_prev;
    const overlapping_start_next = nextRange
      ? _.round(range.st, 2) > _.round(nextRange.st, 2)
      : !!range.validation?.errors?.overlapping_start_next;

    const equal_start_prev = prevRange
      ? _.round(range.st, 2) == _.round(prevRange.st, 2)
      : !!range.validation?.warnings?.equal_start_prev;
    const equal_start_next = nextRange
      ? _.round(range.st, 2) == _.round(nextRange.st, 2)
      : !!range.validation?.warnings?.equal_start_next;

    setValidation(range, {
      errors: {
        overlapping_start_prev: overlapping_start_prev ? options : false,
        overlapping_start_next: overlapping_start_next ? options : false,
      },
      warnings: {
        equal_start_prev: equal_start_prev ? options : false,
        equal_start_next: equal_start_next ? options : false,
      },
    });
  }

  return ranges;
};

export const isStartAfterEnd = (ranges: JobRange[], options: boolean) => {
  if (ranges.length <= 1) return;

  for (const range of ranges) {
    const start_after_end =
      _.round(range.st, 2) > _.round(range.et, 2) ? options : false;

    setValidation(range, {
      errors: {
        start_after_end,
      },
    });
  }

  return ranges;
};

export const isLessThanSeconds = (
  ranges: JobRange[],
  options: {
    minSeconds: number;
  }
) => {
  for (const range of ranges) {
    const rangeLength = _.round(range.et, 2) - _.round(range.st, 2);

    const isLessThanSeconds = rangeLength < options.minSeconds;

    const errors = {
      is_less_than_seconds: isLessThanSeconds ? options : false,
    };

    setValidation(range, {
      errors,
    });
  }

  return ranges;
};

export const isLessThanFrames = (
  ranges: JobRange[],
  options: {
    minFrames: number;
    frameLength: number;
  }
) => {
  for (const range of ranges) {
    const rangeLength = _.round(range.et, 2) - _.round(range.st, 2);

    const isLessThanFrames =
      rangeLength < options.minFrames * options.frameLength;

    const errors = {
      is_less_than_frames: isLessThanFrames ? options : false,
    };

    setValidation(range, {
      errors,
    });
  }

  return ranges;
};

export const isLessThanDynamic = (
  ranges: JobRange[],
  options: {
    minSeconds: number;
    minFrames: number;
    frameLength: number;
  }
) => {
  for (const range of ranges) {
    const rangeLength = _.round(range.et, 2) - _.round(range.st, 2);

    const isLessThanDynamic =
      rangeLength <
      options.minSeconds + options.minFrames * options.frameLength;

    const errors = {
      is_less_than_dynamic: isLessThanDynamic ? options : false,
    };

    setValidation(range, {
      errors,
    });
  }

  return ranges;
};

export const isLessThanFramesBetweenRanges = (
  ranges: JobRange[],
  options: {
    minFrames: number;
    frameLength: number;
  }
) => {
  for (const [i, range] of ranges.entries()) {
    const minSecondsBetweenRanges = options.minFrames * options.frameLength;

    const prevRange = i > 0 ? ranges[i - 1] : null;
    const nextRange = i !== ranges.length - 1 ? ranges[i + 1] : null;

    const isLessThanFramesBetweenPrev = prevRange
      ? _.round(_.round(range.st, 2) - _.round(prevRange.et, 2), 2) <
        minSecondsBetweenRanges
        ? options
        : false
      : !!range.validation?.errors?.is_less_than_frames_between_prev;
    const isLessThanFramesBetweenNext = nextRange
      ? _.round(_.round(nextRange.st, 2) - _.round(range.et, 2), 2) <
        minSecondsBetweenRanges
        ? options
        : false
      : !!range.validation?.errors?.is_less_than_frames_between_next;

    setValidation(range, {
      errors: {
        is_less_than_frames_between_prev: isLessThanFramesBetweenPrev,
        is_less_than_frames_between_next: isLessThanFramesBetweenNext,
      },
    });
  }

  return ranges;
};

export const isMoreThanSeconds = (
  ranges: JobRange[],
  options: {
    maxSeconds: number;
  }
) => {
  for (const range of ranges) {
    const rangeLength = _.round(range.et, 2) - _.round(range.st, 2);

    const isMoreThanSeconds = rangeLength > options.maxSeconds;

    const errors = {
      is_more_than_seconds: isMoreThanSeconds ? options : false,
    };

    setValidation(range, {
      errors,
    });
  }

  return ranges;
};

/* --- Time Validations ---  */

/* --- String Validations ---  */

export const isMoreThanCharsPerSecond = (
  ranges: JobRange[],
  options: {
    charsPerSecond: number;
  }
) => {
  for (const range of ranges) {
    const rangeDuration = (range.et - range.st) * 1000;
    const charsInRange = _.sumBy(range.words, "word.length");
    const charsPerSecond = charsInRange / rangeDuration;
    const maxCharsPerSecond = options.charsPerSecond / 1000;
    const isMoreThanCharsPerSecond =
      options.charsPerSecond > 0 && charsPerSecond > maxCharsPerSecond;
    const errors = {
      is_more_than_chars_per_second: isMoreThanCharsPerSecond ? options : false,
      is_more_than_words_per_minute: false,
    };
    setValidation(range, {
      errors,
    });
  }
  return ranges;
};

export const isMoreThanWordsPerMinute = (
  ranges: JobRange[],
  options: {
    wordsPerMinute: number;
  }
) => {
  for (const range of ranges) {
    const rangeDuration = range.et - range.st;
    const wordsInRange = range.words.length;
    const wordsPerMinute = wordsInRange / rangeDuration;
    const maxWordsPerMinute = options.wordsPerMinute / 60;
    const isMoreThanWordsPerMinute =
      options.wordsPerMinute > 0 && wordsPerMinute > maxWordsPerMinute;
    const errors = {
      is_more_than_words_per_minute: isMoreThanWordsPerMinute ? options : false,
      is_more_than_chars_per_second: false,
    };
    setValidation(range, {
      errors,
    });
  }

  return ranges;
};

export const checkReadSpeed = (
  ranges: JobRange[],
  options: {
    readSpeed: ReadSpeed;
    charsPerSecond: number;
    wordsPerMinute: number;
  }
) => {
  if (options.readSpeed === ReadSpeed.WPM) {
    isMoreThanWordsPerMinute(ranges, {
      wordsPerMinute: options.wordsPerMinute,
    });
  } else if (options.readSpeed === ReadSpeed.CPS) {
    isMoreThanCharsPerSecond(ranges, {
      charsPerSecond: options.charsPerSecond,
    });
  }
};

export const isMoreThanCharsInRange = (
  ranges: JobRange[],
  options: {
    maxCharsInRange: number;
  }
) => {
  for (const range of ranges) {
    const rangeCharsLength = range.words
      .map((word) => word.word)
      .join(" ").length;

    const isInvalid = rangeCharsLength > options.maxCharsInRange;

    const errors = {
      is_more_than_chars_in_range: isInvalid ? options : false,
    };

    setValidation(range, {
      errors,
    });
  }

  return ranges;
};

/* --- String Validations ---  */

/* --- Line Validations ---  */

export const isMoreThanLines = (
  ranges: JobRange[],
  options: {
    maxLines: number;
  }
) => {
  for (const range of ranges) {
    const highestLineIndex = _.max(_.map(range.words, (w) => w.line_ix)) || 0;

    const rangeLines = highestLineIndex + 1;

    const isMoreThanLines = rangeLines > options.maxLines;
    const errors = {
      is_more_than_lines: isMoreThanLines ? options : false,
    };

    setValidation(range, {
      errors,
    });
  }

  return ranges;
};

export const isMoreThanCharsInLine = (
  ranges: JobRange[],
  options: {
    maxCharsInLine: number;
  }
) => {
  for (const range of ranges) {
    const rangeStringLines = _.chain(range.words)
      .groupBy("line_ix")
      .values()
      .map((line) => line.map((w) => w.word).join(" "))
      .value();

    const invalidLines = _.chain(rangeStringLines)
      .map((line, i) => {
        const isInvalid = line.length > options.maxCharsInLine;
        return isInvalid ? i + 1 : null;
      })
      .compact()
      .value();

    const errors = {
      is_more_than_chars_in_line: !_.isEmpty(invalidLines)
        ? {
            ...options,
            invalidLines: invalidLines.join(", "),
          }
        : false,
    };

    setValidation(range, {
      errors,
    });
  }

  return ranges;
};

export const isMoreThanCharsInLineDynamic = (
  ranges: JobRange[],
  options: {
    maxCharsInLine: {
      [numOfRows: string]: number;
    };
  }
) => {
  for (const range of ranges) {
    const rangeStringLines = _.chain(range.words)
      .groupBy("line_ix")
      .values()
      .map((line) => line.map((w) => w.word).join(" "))
      .value();

    const rowCount = rangeStringLines.length;
    const maxCharsInLineWithNumKeys = _.mapKeys(
      options.maxCharsInLine,
      (value, key) => {
        return key === "lineOne" ? 1 : 2;
      }
    );

    const maxCharsInLine = maxCharsInLineWithNumKeys[rowCount.toString()];

    const invalidLines = _.chain(rangeStringLines)
      .map((line, i) => {
        const isInvalid = line.length > maxCharsInLine;
        return isInvalid ? i + 1 : null;
      })
      .compact()
      .value();

    const errorParams = { ...options, maxCharsInLine };
    const errors = {
      is_more_than_chars_in_line_dynamic: !_.isEmpty(invalidLines)
        ? {
            ...errorParams,
            invalidLines: invalidLines.join(", "),
          }
        : false,
    };

    setValidation(range, {
      errors,
    });
  }

  return ranges;
};

export const isRangeStartsWith = (
  ranges: JobRange[],
  options: {
    startsWith: "string";
  }
) => {
  for (const range of ranges) {
    const rangeText = range.words.map((word) => word.word).join(" ");

    const isStartsWith = rangeText.startsWith(options.startsWith);

    const errors = {
      is_range_starts_with: isStartsWith ? options : false,
    };

    setValidation(range, {
      errors,
    });
  }

  return ranges;
};

export const isRangeEndsWith = (
  ranges: JobRange[],
  options: {
    endsWith: "string";
  }
) => {
  for (const range of ranges) {
    const rangeText = range.words.map((word) => word.word).join(" ");

    const isEndsWith = rangeText.endsWith(options.endsWith);

    const errors = {
      is_range_ends_with: isEndsWith ? options : false,
    };

    setValidation(range, {
      errors,
    });
  }

  return ranges;
};

/* --- Line Validations ---  */

const setValidation = (range: JobRange, validation: RangeValidationResults) => {
  if (!range) return;

  const mergedValidation = {
    errors: _.omitBy(
      {
        ...range.validation?.errors,
        ...validation.errors,
      },
      (e) => e === false
    ),
    warnings: _.omitBy(
      {
        ...range.validation?.warnings,
        ...validation.warnings,
      },
      (w) => w === false
    ),
  };

  if (
    _.isEmpty(mergedValidation.errors) &&
    _.isEmpty(mergedValidation.warnings)
  ) {
    delete range.validation;
  } else {
    range.validation = mergedValidation;
  }
};

// NOT IN USE
export const defaultValidationParams = {
  isOverlapping: {},
  isStartTimeOverlapping: {},
  isStartAfterEnd: {},
  isLessThanFrames: {
    minFrames: 25,
    frameLength: 40,
  },
  isLessThanFramesBetweenRanges: {
    minFrames: 1,
    frameLength: 40,
  },
  isLessThanSeconds: {
    minSeconds: 1,
  },
  isLessThanDynamic: {
    minSeconds: 1,
    minFrames: 10,
  },
  isMoreThanSeconds: {
    maxSeconds: 1,
  },
  isMoreThanLines: {
    maxLines: 2,
  },
  isMoreThanCharsInLine: {
    maxCharsInLine: 42,
  },
  isMoreThanCharsInLineDynamic: {
    maxCharsInLine: {
      lineOne: 50,
      lineTwo: 50,
    },
  },
  isMoreThanCharsInRange: {
    maxCharsInRange: 90,
  },
  checkReadSpeed: {},
  // isRangeStartsWith: {},
  // isRangeEndsWith: {}
};

export default {
  isOverlapping,
  isStartTimeOverlapping,
  isStartAfterEnd,
  isLessThanFrames,
  isLessThanFramesBetweenRanges,
  isLessThanSeconds,
  isLessThanDynamic,
  isMoreThanSeconds,
  isMoreThanLines,
  isMoreThanCharsInLine,
  isMoreThanCharsInLineDynamic,
  isMoreThanCharsInRange,
  isRangeStartsWith,
  isRangeEndsWith,
  checkReadSpeed,
};
