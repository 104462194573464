export enum Align {
  start = "start",
  end = "end",
  center = "center",
  justify = "justify",
  right = "right",
  left = "left",
}

export enum TextDecoration {
  bold = "bold",
  italic = "italic",
  underline = "underline",
}

export enum ListTypeName {
  MINUS = "minus",
  BRACKETS = "brackets",
  HEBREW = "hebrew",
  NONE = "none",
}

export enum ListType {
  minus = "1-",
  brackets = "[1]",
  hebrew = "א.",
  none = "none",
}

export enum CalendarsTypes {
  regular = "regular",
  hebrew = "hebrew",
  muslim = "muslim",
}

export interface LogoSettings {
  isActive: boolean;
  size: number;
  align: Align;
  url?: string;
  file?: any;
}

export interface ImageWithRatio {
  buffer: ArrayBuffer;
  width: number;
  height: number;
}

export interface FontSettings {
  fontFamily: string;
  fontSize: number;
  align: Align;
  color: string;
  backgroundColor: string;
  textDecoration: TextDecoration[];
}
