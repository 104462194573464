import * as React from "react";
import _ from "lodash";
import { FC } from "react";

import classNames from "classnames";

import { getTimeStringFromSecs } from "../../helpers/time";

import "./TimerPicker.scss";

interface TimerPickerReadOnlyProps {
  className?: string;
  value: string | number;
  step: number;
  deductTime?: () => void;
  addTime?: () => void;
  showMillis?: boolean;
  offset?: number;
  disabled?: boolean;
}

export const TimerPickerReadyOnly: FC<TimerPickerReadOnlyProps> = ({
  className,
  value,
  step = 0.1,
  addTime,
  deductTime,
  offset = 0,
  disabled = false,
}) => {
  const timeString = React.useMemo(() => {
    if (_.isNumber(value)) {
      const _timeString = getTimeStringFromSecs(value + offset, false, true);
      return _timeString;
    }
  }, [value]);

  return (
    <div className={classNames("TimerPicker", className, { disabled })}>
      <div className="timerContainer">
        <div className="readOnlyTime">{timeString}</div>
      </div>
      {addTime && !disabled && (
        <div className="minusButton timingButton" onClick={addTime}>
          +
        </div>
      )}
      {deductTime && !disabled && (
        <div className="plusButton timingButton" onClick={deductTime}>
          −
        </div>
      )}
    </div>
  );
};

export default TimerPickerReadyOnly;
