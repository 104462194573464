export const getTimeStringFromSecs = (
  numOfSecs: number,
  onlyMins = false,
  withMilli = false
): string => {
  const stringifiedNumber = numOfSecs.toString();
  const [secondsStr, milisecondsStr] = stringifiedNumber.split(".");
  const fixedNumber = Number(
    milisecondsStr ? `${secondsStr}.${milisecondsStr.slice(0, 3)}` : secondsStr
  );

  const milliseconds = (fixedNumber % 1).toFixed(3).substring(2);
  const seconds = fixedNumber % 60;
  const minutes = (fixedNumber / 60) % 60;
  const hours = fixedNumber / 3600;

  if (onlyMins)
    return (
      get2DigitNumber(fixedNumber / 60) +
      ":" +
      get2DigitNumber(fixedNumber % 60) +
      (withMilli ? "." + milliseconds : "")
    );

  return (
    get2DigitNumber(hours) +
    ":" +
    get2DigitNumber(minutes) +
    ":" +
    get2DigitNumber(seconds) +
    (withMilli ? "." + milliseconds : "")
  );
};

export const get2DigitNumber = (n: number): number | string => {
  const floored = Math.floor(n);
  if (floored < 10) return `0${floored}`;
  return floored;
};
