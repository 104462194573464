import axios from "axios";

const instance = axios.create();

export function cloudinaryService(config: any) {
  async function upload(file: File) {
    const cloudinaryEndPoint = `https://api.cloudinary.com/v1_1/${config.cloudinary.cloud_name}/image/upload`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("cloud_name", config.cloudinary.cloud_name);
    formData.append("upload_preset", config.cloudinary.upload_preset);
    formData.append("api_key", config.cloudinary.api_key);
    const results = await instance.post(cloudinaryEndPoint, formData);
    if (results.status !== 200) {
      throw new Error("Could not upload image");
    }
    return results.data.url;
  }

  return {
    upload,
  };
}
