import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import {
  faAt,
  faPhone,
  IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { openMail, openTel, openWhatsapp } from "./../../utils/leads";

import "./ContactUsModal.scss";

export interface ContactUsProps {
  cancel: () => void;
  title: string;
}

export const ContactUsModal = () => {
  const { t } = useTranslation();
  const phoneNumer = "+972508820814";
  const email = "support@sumit-ai.com";

  const details = [
    {
      detail: phoneNumer,
      icon: faPhone,
      action: () => {
        openTel(phoneNumer);
      },
    },
    {
      detail: phoneNumer,
      icon: faWhatsapp as IconDefinition,
      action: () => {
        openWhatsapp(phoneNumer);
      },
    },
    {
      detail: email,
      icon: faAt,
      action: () => {
        openMail(email, "Sumit-AI Contact Us");
      },
    },
  ];

  return (
    <Box className="ContactUsModal" py="25px" px={"45px"}>
      <Box className="title" display={"flex"} justifyContent={"center"}>
        {t("contact_us")}
      </Box>
      <Box
        className="title"
        display={"flex"}
        justifyContent={"start"}
        py="20px"
      >
        {t("contact_us_details")}
      </Box>
      <Box
        className="details"
        display={"flex"}
        flexDirection={"column"}
        alignContent={"start"}
        justifyContent={"start"}
      >
        {details.map((d, i) => (
          <Box
            key={i}
            className="contactDetail"
            display={"flex"}
            gap={"15px"}
            py={"15px"}
            onClick={d.action}
          >
            <div>
              <FontAwesomeIcon icon={d.icon} />
            </div>
            <div>{d.detail}</div>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
