const LocalStorageService = () => {
  const setItem = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  const getItem = <T>(key: string): T | null => {
    const cached = localStorage.getItem(key);
    if (!cached) return null;
    const item: T = JSON.parse(cached);
    return item;
  };

  const removeItem = (key: string) => {
    localStorage.removeItem(key);
  };

  const clearAll = () => {
    localStorage.clear();
  };

  return { setItem, getItem, removeItem, clearAll };
};

export default LocalStorageService();
