import _ from "lodash";
import { FixedSizeList as List } from "react-window";
import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import { ListItemText, ThemeProvider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilterCircleXmark,
  faSquare,
} from "@fortawesome/pro-light-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { bazarTheme, grayColor4 } from "../../../bazar-theme";
import { Checkbox } from "../../Checkbox/Checkbox";
import { Option } from "../../MultiSelect/MultiSelect";
import { SearchInput } from "../../SearchInput/SearchInput";

import "./ListFilter.scss";

export interface ListFilterProps extends HTMLAttributes<HTMLDivElement> {
  options: Option[];
  selected: any[];
  setSelected?: (selected: any[]) => void;
  isMulti?: boolean;
}
const ITEM_SIZE = 35;
const LIST_HEIGHT = 200;
export const ListFilter = ({
  options,
  selected = [],
  setSelected = (selected: any[]) => _.noop(selected),
  isMulti = true,
}: ListFilterProps) => {
  const searchInputRef = useRef<{ inputRef: HTMLInputElement }>(null);
  const [search, setSearch] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);

  useEffect(() => {
    focusInput();
  }, [searchInputRef.current?.inputRef]);

  const reset = () => {
    setSelected([]);
  };

  const handleSelect = (option: Option) => {
    if (!isMulti) {
      setSelected([option.value]);
      return;
    }
    let newSelected = [...selected];
    if (selected.includes(option.value)) {
      newSelected = selected.filter((s) => s !== option.value);
    } else {
      newSelected.push(option.value);
    }
    setSelected(newSelected);
    if (searchInputRef?.current?.inputRef) {
      focusInput();
    }
  };

  const focusInput = () => {
    const focusedElement = document.activeElement;
    if (searchInputRef.current?.inputRef) {
      const { inputRef } = searchInputRef.current;
      if (
        focusedElement !== searchInputRef.current.inputRef &&
        inputRef instanceof HTMLInputElement
      ) {
        searchInputRef.current?.inputRef.focus();
      }
    }
  };

  const filterOptionsBySearchTerm = (term: string) => {
    return options.filter((i: Option) => {
      const label = String(i.label).trim().toLowerCase();
      const searchTerm = term.trim().toLowerCase();
      return label.includes(searchTerm);
    });
  };

  useEffect(() => {
    setFilteredOptions(filterOptionsBySearchTerm(search));
  }, [options]);

  useEffect(() => {
    const handleSearch = (value: string) => {
      let newFiltered = [];
      if (value) {
        newFiltered = filterOptionsBySearchTerm(value);
      } else {
        newFiltered = options;
      }
      setFilteredOptions(newFiltered);
    };
    handleSearch(search);
  }, [search]);

  const Row = ({ index, style }: any) => {
    const option = filteredOptions[index];
    return (
      <div
        style={style}
        className={"FilterListItem"}
        onClick={() => handleSelect(filteredOptions[index])}
        key={option.label}
      >
        <ListItemText>{option.label}</ListItemText>
        <Checkbox
          icon={<FontAwesomeIcon icon={faSquare} color={"#FDB810"} />}
          checkedIcon={
            <FontAwesomeIcon icon={faCheckSquare} color={"#FDB810"} />
          }
          checked={selected.includes(option.value)}
        />
      </div>
    );
  };

  const getHeight = () => {
    if (filteredOptions.length > 4) return LIST_HEIGHT;
    return filteredOptions.length * ITEM_SIZE;
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <div className="BazarListFilter">
        <div className="searchWrapper">
          <SearchInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              backgroundColor: "#e8ecf1",
              color: grayColor4,
              height: "auto",
            }}
            placeholder={"Search"}
            showTextInput={true}
            showSearchIcon={false}
            ref={searchInputRef}
          />
          <FontAwesomeIcon
            icon={faFilterCircleXmark}
            onClick={reset}
            style={{ cursor: "pointer", padding: "0 8px" }}
          />
        </div>
        {filteredOptions.length ? (
          <List
            height={getHeight()}
            itemCount={filteredOptions.length}
            itemSize={ITEM_SIZE}
            width={"auto"}
          >
            {Row}
          </List>
        ) : (
          <ListItemText sx={{ height: ITEM_SIZE }} className={"NoOptionsItem"}>
            No options
          </ListItemText>
        )}
      </div>
    </ThemeProvider>
  );
};
