import { create } from "zustand";
import { Metadata, SttModel } from "@sumit-platforms/types";

export type MetadataStore = {
  sttModels: SttModel[];
  setMetadata: (metadata: Partial<Metadata>) => void;
  lastUpdated: Date | null;
};

export const metadataStore = create<MetadataStore>((set, get) => ({
  sttModels: [],
  setMetadata: (metadata: Partial<Metadata>) => {
    set((state: MetadataStore) => {
      return { ...state, ...metadata, lastUpdated: new Date() };
    });
  },
  lastUpdated: null,
}));
