import { create } from "zustand";
import {
  ActiveUpload,
  PendingUpload,
  Upload,
  UploadProgress,
} from "@sumit-platforms/types";

export type SetUploads = (uploads: Upload[]) => Upload[];
export type SetPendingUploads = (
  pendingUploads: PendingUpload[]
) => PendingUpload[];
export type SetActiveUploads = (
  activeUploads: ActiveUpload[]
) => ActiveUpload[];
export type SetSucceedUploads = (succeedUploads: Upload[]) => Upload[];

export type UploadStore = {
  uploads: Upload[];
  pendingUploads: PendingUpload[];
  activeUploads: ActiveUpload[];
  succeedUploads: Upload[];
  progress: { [index: string]: UploadProgress | null };
  uploadInfoText: { [index: string]: string };
  setUploads: (setFunc: SetUploads) => void;
  setPendingUploads: (setFunc: SetPendingUploads) => void;
  getPendingUploads: () => PendingUpload[];
  setActiveUploads: (setFunc: SetActiveUploads) => void;
  getActiveUploads: () => ActiveUpload[];
  getSucceedUploads: () => Upload[];
  setSucceedUploads: (setFunc: SetSucceedUploads) => void;
  getProgress: () => { [index: string]: UploadProgress | null };
  setProgress: (idUpload: string, progress: UploadProgress | null) => void;
  getUploadInfoText: () => { [index: string]: string };
  setUploadInfoText: (idUpload: string, text: string) => void;
  clearSucceedUploads: () => void;
};

export const uploadStore = create<UploadStore>((set, get) => ({
  uploads: [],
  pendingUploads: [],
  activeUploads: [],
  succeedUploads: [],
  progress: {},
  uploadInfoText: {},
  setUploads: (value) => {
    set((state: UploadStore) => {
      const updatedUploads = value(state.uploads);
      return { ...state, uploads: updatedUploads };
    });
  },
  getPendingUploads: () => get().pendingUploads,
  setPendingUploads: (value) => {
    set((state: UploadStore) => {
      const updatedPendingUploads = value(state.pendingUploads);
      return { ...state, pendingUploads: updatedPendingUploads };
    });
  },
  getActiveUploads: () => get().activeUploads,
  setActiveUploads: (value) => {
    set((state: UploadStore) => {
      const updatedActiveUploads = value(state.activeUploads);
      return { ...state, activeUploads: updatedActiveUploads };
    });
  },
  getSucceedUploads: () => get().succeedUploads,
  setSucceedUploads: (value) => {
    set((state: UploadStore) => {
      const updatedSucceedUploads = value(state.succeedUploads);
      return { ...state, succeedUploads: updatedSucceedUploads };
    });
  },
  getProgress: () => get().progress,
  setProgress: (idUpload: string, progress: UploadProgress | null) => {
    set((state: UploadStore) => {
      const updatedProgress = state.progress;
      updatedProgress[idUpload] = progress;
      return { ...state, progress: updatedProgress };
    });
  },
  getUploadInfoText: () => get().uploadInfoText,
  setUploadInfoText: (idUpload: string, text: string) => {
    set((state: UploadStore) => {
      const updatedInfoText = state.uploadInfoText;
      updatedInfoText[idUpload] = text;
      return { ...state, infoText: updatedInfoText };
    });
  },
  clearSucceedUploads: () => {
    set((state: UploadStore) => {
      return { ...state, succeedUploads: [] };
    });
  },
}));
