import _ from "lodash";
import React from "react";

export const handleKeyboardShortcut = (
  e: React.KeyboardEvent,
  code: string[],
  conditions: boolean[] = [],
  preventDefault = true
): boolean => {
  if (
    code.includes(e.nativeEvent.code) &&
    (conditions ? _.every(conditions) : true)
  ) {
    if (preventDefault) {
      e.stopPropagation();
      e.preventDefault();
    }
    return true;
  }
  return false;
};
