import { FeatureFlags } from "@sumit-platforms/types";
import {
  FlagProvider,
  useFlag,
  useUnleashContext,
} from "@unleash/proxy-client-react";

export const useFeatureFlag = (flagName: FeatureFlags) => {
  const isEnabled = useFlag(flagName || "");
  return isEnabled;
};

export const getFeatureFlagContext = ({ config }: { config?: any }) => {
  if (!config?.featureFlags) console.error("Missing config.featureFlags");
  const unleashConfig = {
    url: config?.featureFlags?.host,
    clientKey: config?.featureFlags?.apiKey,
    refreshInterval: 3000,
    appName: config?.featureFlags?.appName,
  };
  return ({ children }: { children?: React.ReactNode }) => (
    <FlagProvider config={unleashConfig}>{children}</FlagProvider>
  );
};

export const useAttachFFContext = useUnleashContext;
export type { FeatureFlags };
