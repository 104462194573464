import React, { useMemo } from "react";
import i18n from "i18next";
import {
  ConfirmModal,
  CustomModalButton,
} from "../core/ConfirmModal/ConfirmModal";
import { useNavigate } from "react-router";
import { StoreApi, UseBoundStore } from "zustand";
import { ModalStore } from "../store/modal";

export const useNavigateOutPrompt = ({
  useModal,
  onBeforeConfirm,
}: {
  useModal: UseBoundStore<StoreApi<ModalStore>>;
  onBeforeConfirm: () => void;
}) => {
  const navigate = useNavigate();
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const customButtons: CustomModalButton[] = useMemo(
    () => [
      {
        shouldShowLoader: false,
        text: i18n.t("exit_without_save"),
      },
      {
        onBeforeConfirm: async () => {
          await onBeforeConfirm();
        },
        text: i18n.t("save_and_exit"),
        shouldShowLoader: true,
      },
    ],
    []
  );

  const closeModal = () => {
    setModalType("info");
    clearModalContent();
  };

  const openConfirmModal = ({
    title,
    message,
    confirm,
    closeAfterConfirm = true,
    customButtons,
  }: {
    title: string;
    message: string;
    confirm: () => void;
    closeAfterConfirm?: boolean;
    customButtons?: CustomModalButton[];
  }): void => {
    setModalType("info");
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={closeModal}
        closeAfterConfirm={closeAfterConfirm}
        customButtons={customButtons || []}
      />
    );
  };

  return ({ to }: { to: string }) =>
    openConfirmModal({
      title: i18n.t("are_you_sure"),
      message: i18n.t("lost_unsaved"),
      confirm: () => navigate(to),
      closeAfterConfirm: true,
      customButtons,
    });
};
