import _ from "lodash";
import { intervalToDuration } from "date-fns";
import { get2DigitNumber, getTimeStringFromSecs } from "../helpers/time";

export const formatDuration = (duration: number) => {
  const {
    hours = 0,
    minutes = 0,
    seconds = 0,
  } = intervalToDuration({ start: 0, end: duration * 1000 });
  const zeroPad = (num: number | undefined) =>
    num ? String(num).padStart(2, "0") : "00";

  const formattedDuration = [hours, minutes, seconds].map(zeroPad).join(":");

  return formattedDuration;
};

export const compareDates = (
  greater: string | Date,
  lower: string | Date
): boolean => {
  const g = new Date(greater).getTime();
  const l = new Date(lower).getTime();

  return g >= l;
};

export const getDayPeriod = (): "morning" | "afternoon" | "evening" => {
  const hour = new Date().getHours();
  if (hour < 12) return "morning";
  else if (hour < 18) return "afternoon";
  return "evening";
};

const getMillisecondsFromSeconds = (n: number) => {
  const integerPart = Math.round(n * 1000) % 1000;
  return Math.abs(integerPart);
};

export const getTcOffsetByStartTime = (
  tcOffsets?: number[][] | null,
  startTime = 0
): number => {
  if (!tcOffsets) return 0;
  const filteredTcOffsets = tcOffsets.filter((to) => to[0] <= startTime);
  const [tcStartTime, tcOffset] = _.last(filteredTcOffsets) || [0, 0];
  return tcOffset - tcStartTime;
};

export const getTcTime = (
  time: number,
  tcOffsets?: number[][] | null
): number => {
  const tcOffset = getTcOffsetByStartTime(tcOffsets, time);
  const tcSeconds = time + tcOffset;
  const fixedTimecode = tcSeconds % (60 * 60 * 24);
  return fixedTimecode;
};

/**DEPRECATED use getTimecodeFromSeconds*/
export const getTcString = (
  time: number,
  tcOffsets?: number[][] | null,
  withMillis = true
): string => {
  const tcOffset = getTcOffsetByStartTime(tcOffsets, time);
  const fixedSecondsByDay = (time + tcOffset) % (60 * 60 * 24);
  const tc = getTimeStringFromSecs(fixedSecondsByDay, false, withMillis);
  return tc;
};
/**DEPRECATED use getTimecodeFromSeconds*/

export const getTimecodeFromSeconds = (
  time: number,
  options?: {
    tcOffsets?: number[][] | null;
    fps?: number;
  }
): string => {
  const { tcOffsets = [[0, 0]], fps = 25 } = options || {};
  const timeWithOffset = getTcTime(time, tcOffsets);

  const seconds = Math.floor(timeWithOffset);
  const milliseconds = getMillisecondsFromSeconds(timeWithOffset);

  const frameLength = 1000 / fps;
  const frameIndex = Math.round(milliseconds / frameLength)
    .toString()
    .padStart(2, "0");

  const hours = get2DigitNumber(seconds / 3600);
  const minutes = get2DigitNumber((seconds / 60) % 60);
  const secs = get2DigitNumber(seconds % 60);

  const timecodeString = `${hours}:${minutes}:${secs}:${frameIndex}`;
  return timecodeString;
};

export const getSecondsFromTimecode = (
  timecodeString: string,
  options?: { fps?: number }
): number => {
  const { fps = 25 } = options || {};
  const [hours, minutes, seconds, frames] = timecodeString.split(":");
  const milliseconds = Number(frames) / fps;

  return (
    Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds) + milliseconds
  );
};
