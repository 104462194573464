import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Box, IconButton, ListItem, ListItemText } from "@mui/material";
import { Input } from "../Input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";

export const AddNewOption = ({
  creatingNew,
  setCreatingNew,
  label,
  addNewOption,
}: {
  creatingNew: boolean;
  setCreatingNew: (value: boolean) => void;
  label?: string;
  addNewOption?: {
    onApprove: (newValue: string) => any;
    label?: string;
  };
}) => {
  const { t } = useTranslation();

  const [newOptionLoading, setNewOptionLoading] = useState<boolean>(false);
  const [newOptionText, setNewOptionText] = useState<string>("");

  const handleCreateNew = () => {
    setCreatingNew(true);
  };

  const handleApproveNew = async () => {
    if (addNewOption?.onApprove) {
      setNewOptionLoading(true);
      await addNewOption.onApprove(newOptionText);
      setNewOptionLoading(false);
      clearNewOptionState();
    }
  };

  const clearNewOptionState = () => {
    setCreatingNew(false);
    setNewOptionText("");
  };

  const onInputChange = (e: any) => {
    setNewOptionText(e.target?.value || "");
  };

  return (
    <ListItem
      key="addNewOption"
      className="BazarMultiSelectOption addNewOption"
    >
      {creatingNew ? (
        <Box className={"inputWrapper"}>
          <Input
            className={"newOptionInput"}
            type="text"
            value={newOptionText}
            onChange={onInputChange}
            autofocus={true}
            disabled={newOptionLoading}
          />
          <IconButton
            className={"approveNewOption"}
            disabled={!newOptionText || newOptionLoading}
            onClick={handleApproveNew}
          >
            <FontAwesomeIcon icon={faCheck} />
          </IconButton>
          <IconButton
            className={"cancelNewOption"}
            onClick={clearNewOptionState}
            disabled={newOptionLoading}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </Box>
      ) : (
        <ListItemText
          primary={label || t("add_new")}
          className="BazarMultiSelectOptionLabel"
          onClick={handleCreateNew}
        />
      )}
    </ListItem>
  );
};
