import { TableRow, TableCell, Typography, Icon } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-thin-svg-icons";

import "./NoDataTablePlaceholder.scss";

export const NoDataTablePlaceholder = () => {
  return (
    <TableRow
      sx={{
        width: "100%",
        textAlign: "center",
      }}
    >
      <TableCell
        sx={{
          width: "100%",
          textAlign: "center",
        }}
        colSpan={90}
      >
        <div className={"NoDataContent"}>
          <FontAwesomeIcon
            className={"NoDataIcon"}
            icon={faExclamationCircle}
          />
          <Typography
            sx={{
              fontSize: 14,
            }}
            className={"NoDataText"}
          >
            No data
          </Typography>
        </div>
      </TableCell>
    </TableRow>
  );
};
