import { useCallback, useEffect, useState } from "react";

export const useEllipsisObserver = (
  ref: React.RefObject<HTMLDivElement | null>
) => {
  const [isEllipsised, setIsEllipsised] = useState<boolean>(false);

  const checkEllipsis = useCallback(() => {
    if (ref.current) {
      const newIsEllipsized = ref.current.scrollWidth > ref.current.offsetWidth;
      if (newIsEllipsized !== isEllipsised) {
        setIsEllipsised(newIsEllipsized);
      }
    }
  }, [isEllipsised, ref]);

  useEffect(() => {
    if (ref.current) {
      checkEllipsis();
    }
  }, [ref, checkEllipsis]);

  return { isEllipsised, checkEllipsis };
};
