import * as EN_countries from "./EN/countries.json";
import * as EN_languages from "./EN/languages.json";
import * as EN_validations from "./EN/validations.json";
import * as EN_common from "./EN/common.json";

import * as HE_countries from "./HE/countries.json";
import * as HE_languages from "./HE/languages.json";
import * as HE_validations from "./HE/validations.json";
import * as HE_common from "./HE/common.json";

import * as AR_countries from "./AR/countries.json";
import * as AR_languages from "./AR/languages.json";
import * as AR_common from "./AR/common.json";

export const EN = {
  ...EN_countries,
  ...EN_languages,
  ...EN_common,
  ...EN_validations,
};
export const HE = {
  ...HE_countries,
  ...HE_languages,
  ...HE_common,
  ...HE_validations,
};
export const AR = { ...AR_countries, ...AR_languages, ...AR_common };
