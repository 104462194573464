import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import _ from "lodash";
import { Box } from "@mui/material";
import { ZeroSubtitle, ZeroSubtitleLine } from "@sumit-platforms/types";
import { InputPair } from "../InputPair/InputPair";
import { faPlusCircle, faXmarkCircle } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { SelectSimpleOption } from "../SelectSimple/SelectSimple";

interface ZeroSubtitleFormProps {
  zeroSubtitle: ZeroSubtitle;
  onChange: (newZeroSubtitle: ZeroSubtitle) => void;
  valueOptions: SelectSimpleOption[];
}

export const ZeroSubtitleForm = forwardRef(
  ({ zeroSubtitle, valueOptions, onChange }: ZeroSubtitleFormProps, ref) => {
    const { t } = useTranslation();
    const [newPairKey, setNewPairKey] = useState("");
    const [newPairValue, setNewPairValue] = useState<any>("");
    const labels = useMemo(() => ({ key: t("label"), value: t("value") }), []);

    useImperativeHandle(
      ref,
      () => {
        return {
          title: newPairKey,
          value: newPairValue,
        } as ZeroSubtitleLine;
      },
      [newPairValue, newPairKey]
    );

    const onNewPairKeyChange = (e: any) => {
      setNewPairKey(e.target.value);
    };
    const onNewPairValueChange = (option: SelectSimpleOption) => {
      setNewPairValue(option);
    };

    const handleChange = (content: ZeroSubtitle["content"]) => {
      onChange({ ...zeroSubtitle, content: [...content] });
    };

    const handleRemovePair = useCallback(
      (index: number) => {
        handleChange(zeroSubtitle.content.filter((line, ix) => ix !== index));
      },
      [handleChange, zeroSubtitle.content]
    );

    const handleAddPair = useCallback(() => {
      handleChange([
        ...zeroSubtitle.content,
        { value: newPairValue, title: newPairKey.trim() },
      ]);
      setNewPairKey("");
      setNewPairValue("");
    }, [handleChange, newPairKey, newPairValue, zeroSubtitle.content]);

    const onKeyChange = (e: any, ix: number) => {
      const newContent = [...zeroSubtitle.content];
      newContent[ix].title = e.target.value.trim();
      handleChange(newContent);
    };
    const onValueChange = (option: SelectSimpleOption, ix: number) => {
      const newContent = [...zeroSubtitle.content];
      newContent[ix].value = option as any;
      handleChange(newContent);
    };

    return (
      <Box width={"100%"} maxHeight={"12rem"} overflow={"auto"}>
        {zeroSubtitle?.content?.map((line, ix) => {
          return (
            <InputPair
              keyValue={line.title || ""}
              value={line.value || ""}
              key={`${line.value}-${ix}`} // index must be used as key here
              options={valueOptions}
              icon={{
                action: () => handleRemovePair(ix),
                icon: faXmarkCircle,
                tooltip: t("remove"),
              }}
              onKeyChange={(e) => onKeyChange(e, ix)}
              onValueChange={(option) => onValueChange(option, ix)}
              showLabels={ix === 0}
              labels={labels}
              optional={{ input: true }}
              sx={{ padding: "8px 0" }}
            />
          );
        })}
        <InputPair
          keyValue={newPairKey || ""}
          value={newPairValue || ""}
          onKeyChange={onNewPairKeyChange}
          onValueChange={onNewPairValueChange}
          options={valueOptions}
          showLabels={_.isEmpty(zeroSubtitle.content)}
          labels={labels}
          optional={{ input: true }}
          sx={{ padding: "8px 0" }}
          icon={{
            tooltip: t("add"),
            disabled: !newPairValue,
            action: newPairValue ? handleAddPair : undefined,
            icon: faPlusCircle,
          }}
        />
      </Box>
    );
  }
);
