import { AlertProps } from "@mui/material";
import { create } from "zustand";

/* -------------------------------------- */
/* ------------ DEPRECATED -------------- */
/* -------------------------------------- */
export interface EnchancedAlert extends AlertProps {
  message: string;
  title?: string;
}

export type AlertStore = {
  alert: EnchancedAlert | null;
  setAlert: (alert: EnchancedAlert | null) => void;
};

export const useAlert = create<AlertStore>((set) => ({
  alert: null,
  setAlert: (alert: EnchancedAlert | null) => {
    set((state: AlertStore) => {
      return { ...state, alert };
    });
  },
}));
