import React, { FC, useState } from "react";
import classNames from "classnames";
import { Box } from "@mui/material";
import { PasswordReveal } from "../PasswordReveal/PasswordReveal";

import "./FormInput.scss";

interface Props {
  className?: string;
  placeholder?: string;
  error?: string | null;
  register?: any;
  autofocus?: boolean;
  disabled?: boolean;
  type?: "text" | "number" | "password";
}

export const FormInput: FC<Props> = ({
  className,
  placeholder,
  error,
  register,
  autofocus,
  disabled = false,
  type = "text",
}): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box
      mb={2}
      className={classNames("FormInput", className, { error: !!error })}
    >
      <input
        placeholder={placeholder}
        autoFocus={autofocus}
        disabled={disabled}
        type={showPassword ? "text" : type}
        {...register}
      />
      {type === "password" && (
        <PasswordReveal
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
      )}
      {error && <div className="inputError">{error}</div>}
    </Box>
  );
};

export default FormInput;
