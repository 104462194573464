import { Badge, ThemeProvider } from "@mui/material";
import { bazarTheme } from "../../bazar-theme";

interface BadgeWrapperProps {
  invisible?: boolean;
  className?: string;
  badgeContent?: string;
  children?: React.ReactNode;
}

export const BadgeWrapper = ({
  invisible,
  className,
  badgeContent,
  children,
}: BadgeWrapperProps) => {
  return (
    <ThemeProvider theme={bazarTheme}>
      <Badge
        invisible={invisible}
        className={className}
        color="secondary"
        badgeContent={badgeContent}
      >
        {children}
      </Badge>
    </ThemeProvider>
  );
};
