import { Tooltip } from "../Tooltip/Tooltip";
import { secondaryColor } from "../../bazar-theme";
import React from "react";
import { TooltipProps } from "@mui/material";

export const Indicator = ({
  style,
  tooltip,
}: {
  style?: React.CSSProperties;
  tooltip?: {
    title?: string;
    placement?: TooltipProps["placement"];
    style?: React.CSSProperties;
  };
}) => {
  const span = (
    <span
      style={{
        display: "inline-block",
        background: secondaryColor,
        height: "0.4rem",
        width: "0.4rem",
        margin: "0 0 7px 4px",
        borderRadius: "50%",
        ...style,
      }}
    />
  );
  return tooltip ? (
    <Tooltip
      style={tooltip.style}
      placement={tooltip.placement || "right"}
      title={tooltip.title ? tooltip.title : ""}
    >
      {span}
    </Tooltip>
  ) : (
    span
  );
};
