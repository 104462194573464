import classNames from "classnames";
import React, { FC } from "react";

import "./Layout.scss";

export interface LayoutProps {
  isOpen?: boolean;
  navigation?: JSX.Element;
  children: React.ReactNode;
}

export const Layout: FC<LayoutProps> = ({
  isOpen,
  navigation,
  children,
}: LayoutProps) => {
  return (
    <main className={classNames("BazarLayout", { open: isOpen })}>
      <aside>{navigation}</aside>
      <section>{children}</section>
    </main>
  );
};
