import React from "react";

import "./BreathingLoader.scss";

export interface BreathingLoaderProps {
  message?: string;
  title?: string;
  className?: string;
  image?: string;
}

export const BreathingLoader = ({ className, image }: BreathingLoaderProps) => {
  return (
    <div className={`BreathingLoader ${className}`}>
      <img src={image} alt="loading_logo" />
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
