export const openTel = (pn: string) => {
  window.open(`tel:${pn}`);
};

export const openWhatsapp = (pn: string, text?: string) => {
  window.open(`https://wa.me/${pn}?text=${text}`);
};

export const openMail = (email: string, subject?: string) => {
  window.open(`mailto:${email}?subject=${subject}`);
};
