import React from "react";

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { Indicator } from "../Indicator/Indicator";

import "./TableIconActions.scss";

export interface IconAction {
  name: string;
  icon: IconDefinition;
  action: (row: any) => void;
  disabled?: boolean;
  size?: string;
  indicator?: { tooltip: string } | null;
  ref?: HTMLElement;
}

export interface TableIconActionsProps {
  onRowActions: IconAction[];
  row: any;
}

export const TableIconActions = ({
  onRowActions,
  row,
}: TableIconActionsProps) => {
  return (
    <Box className={"BazarTableIconActions"}>
      {onRowActions.map((action, i) => (
        <Box
          className="action"
          key={i}
          sx={onRowActions.length > 1 ? { margin: "0 10px" } : { margin: "0" }}
          title={action.name}
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            if (action.disabled) return;
            action.action(row);
          }}
        >
          {action.indicator && (
            <Indicator
              style={{ position: "absolute", left: 9, top: -3 }}
              tooltip={{
                title: action.indicator.tooltip || "",
                placement: "top",
                style: { padding: "6px" },
              }}
            />
          )}
          <FontAwesomeIcon icon={action.icon} />
        </Box>
      ))}
    </Box>
  );
};
