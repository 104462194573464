import { IndexType, TextualIndexType } from "../Algo/indexSearch";

interface ExtractField {
  indexSearchType: string;
  indexSearchField: string[];
  indexSearchParams?: any;
  indexToSearchIn: string;
  inputType: string;
  inputLabel: string;
  paragraphSearch?: boolean;
}

type ExtractResult = {
  resultId: string;
  queryId: string;
  idJob: number;
  name: string;
  tags: string[];
  timecode: number;
  indexSearchType: IndexType;
  text: string;
  speaker: string;
  transcript: { speaker: string; text: string }[];
  speakers?: string[];
  thumbnail?: string;
  media?: string;
  start: number;
  tcOffsets?: number[][];
};

enum FieldOperator {
  IS = "IS",
  NOT = "NOT",
}
interface ExtractSegmentField {
  type: string;
  operator: FieldOperator;
  value?: string | string[] | number[] | number;
  textualIndexType?: TextualIndexType;
  indexSearchData?: Pick<
    ExtractField,
    | "indexSearchType"
    | "indexSearchField"
    | "indexSearchParams"
    | "indexToSearchIn"
  >;
}

type ExtractQuery = ExtractSegmentField[][];

export { FieldOperator };
export type { ExtractSegmentField, ExtractQuery, ExtractResult, ExtractField };
