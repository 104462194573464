import { create } from "zustand";

export type PlayerStore = {
  zoomValue: number;
  setZoomValue: (zoomValue: number) => void;
};

export const usePlayerStore = create<PlayerStore>((set) => ({
  zoomValue: 100,
  setZoomValue: (zoomValue: number) => {
    set((state: PlayerStore) => {
      return { ...state, zoomValue };
    });
  },
}));
