import React from "react";
import { Chip as MuiChip, ThemeProvider } from "@mui/material";

import { bazarTheme } from "../../bazar-theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

export interface ChipProps {
  label: string;
  action?: () => void;
  moreSuffix?: boolean;
  style?: React.CSSProperties;
}

export const Chip = ({ label, action, moreSuffix, style }: ChipProps) => {
  const handleOnAction = () => {
    if (action) action();
  };
  return (
    <ThemeProvider theme={bazarTheme}>
      <MuiChip
        className={"BazarChip"}
        sx={style}
        color={"secondary"}
        label={`${label}${moreSuffix ? "..." : ""}`}
        onDelete={handleOnAction}
        deleteIcon={action ? <FontAwesomeIcon icon={faTimes} /> : <></>}
      />
    </ThemeProvider>
  );
};
