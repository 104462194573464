import React from "react";
import classNames from "classnames";

import "./Profile.scss";

export interface ProfileProps {
  fullName: string;
  avatarUrl?: string;
  onClick?: () => void;
  showDetails?: boolean;
  greeting?: string;
}

export const Profile = ({
  fullName,
  avatarUrl,
  onClick,
  showDetails,
  greeting,
}: ProfileProps) => {
  const handleOnClick = () => {
    onClick && onClick();
  };
  return (
    <div
      onClick={handleOnClick}
      className="BazarProfile"
      style={
        showDetails
          ? { justifyContent: "flex-start" }
          : { justifyContent: "center" }
      }
    >
      {avatarUrl && (
        <div className="avatarContainer">
          <img src={avatarUrl} alt="avatar_image" />
        </div>
      )}
      <div className={classNames("detailsContainer", { open: showDetails })}>
        {greeting && <div className="greeting">{greeting}</div>}
        <div className="name">{fullName}</div>
      </div>
    </div>
  );
};
