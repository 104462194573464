import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SortOption } from "../core/Sort/Sort";

type AvailableKeys =
  | "transcribe_delivery"
  | "delivery"
  | "name"
  | "created_at"
  | "firstName"
  | "duration"
  | "status"
  | "yearly_target";

export const useSortOptions = (keys: AvailableKeys[]) => {
  const { t } = useTranslation();

  const _options: Record<AvailableKeys, SortOption> = useMemo<
    Record<AvailableKeys, SortOption>
  >(
    () => ({
      transcribe_delivery: {
        orderBy: "transcribe_delivery",
        label: t("transcriber_delivery"),
      },

      delivery: {
        orderBy: "delivery",
        label: t("client_delivery"),
      },

      created_at: {
        label: t("uploaded"),
        orderBy: "created_at",
      },

      name: { label: t("name"), orderBy: "name" },

      firstName: {
        label: t("name"),
        orderBy: "firstName",
      },

      duration: {
        label: t("duration"),
        orderBy: "duration",
      },

      status: {
        label: t("status"),
        orderBy: "status",
      },
      yearly_target: {
        label: t("yearly_target"),
        orderBy: "yearly_target",
      },
    }),
    []
  );

  const sortOptions = useMemo(() => {
    return keys.map((key) => _options[key]);
  }, [keys]);

  return { sortOptions };
};
