import {
  AssignmentTask,
  BonusType,
  Currency,
  Job,
  JobStatus,
  TaskStatus,
} from "@sumit-platforms/types";
import { getPriceStringWithCurrency } from "./formatters";

export const getBonusPriceByType = ({
  jobPrice,
  bonusType,
  bonusPrice,
}: {
  bonusPrice: number;
  jobPrice: number;
  bonusType?: BonusType | null;
}) => {
  switch (bonusType) {
    case BonusType.PERCENTAGE: {
      return (bonusPrice / 100) * jobPrice;
    }
    case BonusType.FIXED: {
      return bonusPrice;
    }
    default: {
      return null;
    }
  }
};

export const getJobBonusPrice = (job: Job, of: "qc" | "transcribe") => {
  if (!job || !job.jobBonus) return null;
  const { bonusType } = job.jobBonus;
  const bonusPrice = job.jobBonus[of] as number;
  if (!bonusPrice || !bonusType) return null;
  const jobPrice = of === "qc" ? job.qcPrice : job.transcribePrice;
  return getBonusPriceByType({ bonusType, bonusPrice, jobPrice });
};

export const getJobIncomeByStatus = (job: Job) => {
  // The function will return the qc or transcribe price according to the status of the job
  if (
    job.status === JobStatus.pending_review ||
    job.status === JobStatus.review
  ) {
    return getJobPriceStringWithBonus(job, "qc");
  }
  return getJobPriceStringWithBonus(job, "transcribe");
};
export const getJobIncomeByAssignments = ({
  job,
  idUser,
}: {
  job: Job;
  idUser?: number;
}) => {
  // The function will return the qc or transcribe price according to the assignments of the job
  if (!idUser) return getJobIncomeByStatus(job);
  const userAssignments = job.assignments?.filter(
    (assignment) =>
      assignment.idUser === idUser &&
      ![TaskStatus.resign, TaskStatus.stopped].includes(assignment.taskStatus)
  );
  if (!userAssignments?.length) return getJobIncomeByStatus(job);

  const isUserQC = userAssignments.some(
    (assignment) => assignment.task === AssignmentTask.qc
  );
  const isUserTranscriber = userAssignments.some(
    (assignment) => assignment.task === AssignmentTask.transcriber
  );

  if (isUserQC && !isUserTranscriber) {
    return getJobPriceStringWithBonus(job, "qc");
  }
  if (isUserTranscriber && !isUserQC) {
    return getJobPriceStringWithBonus(job, "transcribe");
  }

  return getJobIncomeByStatus(job);
};

export const getJobPriceStringWithBonus = (
  job: Job,
  of: "qc" | "transcribe",
  currency: Currency = "ILS"
) => {
  const bonusPrice = getJobBonusPrice(job, of);
  const price = of === "qc" ? job.qcPrice : job.transcribePrice;
  const priceString = getPriceStringWithCurrency({ price, currency });
  const bonusPriceString = bonusPrice
    ? getPriceStringWithCurrency({ price: bonusPrice, currency })
    : null;
  return bonusPriceString
    ? `${priceString.replace(/\u200f/g, "")} + ${bonusPriceString.replace(
        /\u200f/g,
        ""
      )}`
    : priceString;
};

export const calculatePriceByMinutes = (
  value: number,
  durationInSec?: number
) => {
  if (!durationInSec) return;
  const number = (durationInSec / 60) * value;
  return number.toFixed(2);
};
