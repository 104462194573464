import { parsePhoneNumber, validatePhoneNumberLength } from "libphonenumber-js";
import { E164Number } from "libphonenumber-js/types";

const getFormattedPhone = (number: string): E164Number => {
  const parsedPhoneNumber = parsePhoneNumber(number);
  const formattedPhoneNumber = parsedPhoneNumber.number;
  return formattedPhoneNumber;
};

const formatPhoneByCountry = (number: string) => {
  const parsedPhoneNumber = parsePhoneNumber(number).formatInternational();
  return parsedPhoneNumber ? parsedPhoneNumber : "";
};

const validatePhoneNumber = (number: string) => {
  const invalid = validatePhoneNumberLength(number);

  if (invalid) {
    return `auth/phone_${invalid.toLowerCase()}`;
  }

  return true;
};

export { getFormattedPhone, formatPhoneByCountry, validatePhoneNumber };
