import React from "react";
import { IconButton } from "@mui/material";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PasswordReveal = ({ setShowPassword, showPassword }: Props) => {
  return (
    <IconButton
      className="eyeIcon"
      onClick={() => setShowPassword((prev) => !prev)}
      style={{
        padding: 14,
        fontSize: 18,
        width: 20,
        height: 20,
        position: "absolute",
        top: 4,
        right: 4,
      }}
    >
      {showPassword ? (
        <FontAwesomeIcon icon={faEye} size={"sm"} />
      ) : (
        <FontAwesomeIcon icon={faEyeSlash} size={"sm"} />
      )}
    </IconButton>
  );
};
