import classNames from "classnames";
import React from "react";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { SxProps, ThemeProvider, TooltipProps } from "@mui/material";
import { bazarTheme } from "../../bazar-theme";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "../Tooltip/Tooltip";

import "./Button.scss";

export interface ButtonProps {
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  children: string | JSX.Element;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  variant?: "contained" | "text" | "outlined";
  type?: "button" | "submit";
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  color?:
    | "info"
    | "warning"
    | "inherit"
    | "secondary"
    | "primary"
    | "error"
    | "success";
  sx?: SxProps;
  infoTooltip?: string;
  tooltipPlacement?: TooltipProps["placement"];
}

export const Button = ({
  onClick,
  children,
  disabled,
  loading,
  className,
  variant = "contained",
  type,
  startIcon,
  endIcon,
  color,
  sx,
  infoTooltip,
  tooltipPlacement = "top",
}: ButtonProps) => {
  return (
    <ThemeProvider theme={bazarTheme}>
      <MuiLoadingButton
        type={type}
        variant={variant}
        className={classNames("BazarButton", className)}
        onClick={onClick}
        disabled={disabled || loading}
        loading={loading}
        startIcon={startIcon}
        endIcon={endIcon}
        color={color}
        sx={sx}
      >
        {children}
        {infoTooltip && (
          <Tooltip title={infoTooltip} placement={tooltipPlacement || "top"}>
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={{ margin: "auto 0 auto 10px" }}
            />
          </Tooltip>
        )}
      </MuiLoadingButton>
    </ThemeProvider>
  );
};
