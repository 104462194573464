import React, { LegacyRef } from "react";
import { HeadCell } from "../GeneralTable/GeneralTable";
import { Checkbox, TableBody, TableCell, TableRow } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { PopoverAction } from "../../popovers/PopoverAction/PopoverAction";

import "./TableBar.scss";

export interface TableBarProps<TData> {
  headCells: HeadCell<TData>[];
  allSelected: boolean;
  moreThanOneSelected: boolean;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  allowSelect?: boolean;
}

export const TableBar = <TData,>({
  headCells,
  allSelected,
  moreThanOneSelected,
  handleSelectAllClick,
  allowSelect = true,
}: TableBarProps<TData>) => {
  return (
    <TableBody className="BazarTableBar" sx={{ background: "#E8ECF1" }}>
      <TableRow>
        {allowSelect && (
          <TableCell padding="checkbox" sx={{ paddingY: 0 }}>
            <div className="tableBar checkboxWrapper">
              <Checkbox
                icon={<FontAwesomeIcon icon={faSquare} color={"#3C5069"} />}
                checkedIcon={
                  <FontAwesomeIcon icon={faCheckSquare} color={"#3C5069"} />
                }
                checked={allSelected}
                onChange={handleSelectAllClick}
              />
            </div>
          </TableCell>
        )}
        {headCells
          ?.filter((headCell) => !headCell.hideColumn)
          .map((h, i) => (
            <TableCell
              key={i}
              sx={{ paddingTop: 0, paddingBottom: 0 }}
              style={h.style}
            >
              <div className="tableBar">
                {moreThanOneSelected && h.barColData && (
                  <div className="barData">{h.barColData()}</div>
                )}
                {moreThanOneSelected && h.barColAction?.trigger && (
                  <div className="barAction">
                    <PopoverAction
                      popoverClassName="tablePopover"
                      {...h.barColAction}
                    />
                  </div>
                )}
                {h?.barColActions?.map((barColAction, i) => (
                  <div
                    key={i}
                    className="barAction"
                    style={{
                      cursor: "initial",
                    }}
                    ref={
                      barColAction.ref as LegacyRef<HTMLDivElement> | undefined
                    }
                  >
                    <FontAwesomeIcon
                      style={{
                        cursor: !barColAction.disabled ? "pointer" : "auto",
                        fontSize: barColAction.size
                          ? barColAction.size
                          : "inherit",
                      }}
                      icon={barColAction.icon}
                      color={!barColAction.disabled ? "#222936" : "#828D9E"}
                      onClick={(...args) =>
                        !barColAction.disabled && barColAction.action(...args)
                      }
                    />
                  </div>
                ))}
              </div>
            </TableCell>
          ))}
      </TableRow>
    </TableBody>
  );
};
