import _ from "lodash";
import { QueryParams } from "@sumit-platforms/types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
export interface UseQueryParams<TFiltersOptions> {
  queryLimit: number;
  queryOffset: number;
  order: "ASC" | "DESC";
  orderBy: string;
  onBuildQuery?: ({
    query,
  }: {
    query: QueryParams<TFiltersOptions>;
  }) => Promise<void>;
  onResetOffset: () => void;
}

export const useQuery = <TFiltersOptions>(
  {
    queryLimit,
    queryOffset,
    order,
    orderBy,
    onBuildQuery,
    onResetOffset,
  }: UseQueryParams<TFiltersOptions>,
  deps: any[] = []
) => {
  const [loading, setLoading] = useState(false);

  const querySearch = useRef<string>("");
  const queryFilters = useRef<TFiltersOptions>();
  const _queryOffset = useRef(queryOffset);
  const _queryLimit = useRef(queryLimit);
  const _queryOrder = useRef(order);
  const _queryOrderBy = useRef(orderBy);

  const [searchParams] = useSearchParams();

  const _makeQuery = useCallback(async () => {
    try {
      if (onBuildQuery) {
        setLoading(true);
        await onBuildQuery({
          query: {
            search: querySearch.current,
            filters: queryFilters.current,
            offset: _queryOffset.current,
            limit: _queryLimit.current,
            order: _queryOrder.current,
            orderBy: _queryOrderBy.current,
          },
        });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.error({ error: "An error occured on making query" });
      throw err;
    }
  }, [...deps]);

  const debouncedQuery = useCallback(
    _.debounce(() => {
      _resetOffset();
      _makeQuery();
    }, 400),
    [searchParams, ...deps]
  );

  useEffect(() => {
    debouncedQuery();
  }, [searchParams, ...deps]);

  const _resetOffset = () => {
    _queryOffset.current = 0;
    onResetOffset();
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    querySearch.current = e.target.value;
    debouncedQuery();
  };

  const onScroll = async (offset: number) => {
    if (loading) return;
    _queryOffset.current = offset;
    _makeQuery();
  };

  const onSort = (order: "ASC" | "DESC", orderBy: string) => {
    _resetOffset();
    _queryOrder.current = order;
    _queryOrderBy.current = orderBy;
    _makeQuery();
  };

  /* the filters are changing the url, so the call will happen in the searchParams effect */
  const onFilterChange = (query: TFiltersOptions) => {
    _resetOffset();
    queryFilters.current = query;
  };

  return {
    querySearch,
    queryFilters,
    onScroll,
    onFilterChange,
    onSearch,
    onSort,
    loading,
  };
};
