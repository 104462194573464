import { useTranslation } from "react-i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { Button } from "./../../core/Button/Button";

import "./RedirectModal.scss";

export interface RedirectModalProps {
  closeModal: () => void;
  path: string;
  title?: string;
  text?: string;
}

export const RedirectModal = ({
  closeModal,
  path,
  text,
  title,
}: RedirectModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoToJobs = () => {
    navigate(path);
    closeModal();
  };

  return (
    <Grid
      className={"RedirectModal"}
      container
      justifyContent={"center"}
      py={3}
      px={4}
    >
      {title && (
        <Grid item xs={12} display={"flex"} justifyContent={"center"} pb={2}>
          <div className="title">{title}</div>
        </Grid>
      )}
      {text && (
        <Grid item xs={12} display={"flex"} justifyContent={"center"} pb={2}>
          <div className="message">{text}</div>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        pb={2}
        gap={2}
      >
        <Button variant="outlined" onClick={closeModal}>
          {t("close")}
        </Button>
        <Button onClick={handleGoToJobs}>{t("go_to_jobs")}</Button>
      </Grid>
    </Grid>
  );
};
