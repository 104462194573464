import _ from "lodash";
import { Steps } from "../../core/Steps/Steps";
import StepsButtons from "../../core/StepsButtons/StepsButtons";
import { Alert, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface StepsFormProps {
  steps: any[];
  error: string | null;
  handleDone: () => void;
  loading?: boolean;
}

export const StepsForm = ({
  steps,
  error,
  handleDone,
  loading,
}: StepsFormProps) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  return (
    <>
      <div className="authContainer">
        <div className="headerSteps">
          <Steps className="center" steps={steps} currentStep={currentStep} />
        </div>
        <div className="currentStepContainer">
          {_.map(steps, (s, i) =>
            i === currentStep ? <div key={`step-${i}}`}>{s.step}</div> : null
          )}
        </div>
        {error && (
          <Box pb={2}>
            <Alert severity={"error"}>{error}</Alert>
          </Box>
        )}
        <StepsButtons
          steps={steps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          backText={t("back")}
          nextText={t("next")}
          doneText={t("done")}
          done={handleDone}
          disabled={!steps[currentStep].form.useForm.formState.isValid}
          loading={loading}
        />
      </div>
      <div id="verifier-container" style={{ visibility: "hidden" }}></div>
    </>
  );
};
