import _ from "lodash";
import React, { Key, useState, useEffect, Ref } from "react";

const useKeyPress = (targetKey: Key, refs?: any[]) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = (e: KeyboardEvent) => {
      if (
        e.key === targetKey &&
        _.some(
          refs,
          (ref) => ref && ref.current && ref.current.contains(e.target as Node)
        )
      ) {
        setKeyPressed(true);
        e.preventDefault();
      }
    };

    const upHandler = (e: KeyboardEvent) => {
      if (
        e.key === targetKey &&
        _.some(
          refs,
          (ref) => ref && ref.current && ref.current.contains(e.target as Node)
        )
      ) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export default useKeyPress;
