import React, { useState } from "react";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Upload } from "@sumit-platforms/types";

import { Button } from "./../../core/Button/Button";

import "./MergeUploadModal.scss";

export interface MergeUploadModalProps {
  cancel: () => void;
  submit: null | (() => void);
  uploads: Upload[];
  selected: number[];
  setSelected: (selected: number[]) => void;
}

export const MergeUploadModal = ({
  cancel,
  submit,
  uploads,
  selected,
  setSelected,
}: MergeUploadModalProps) => {
  const { t } = useTranslation();
  const [currSelected, setCurrSelected] = useState<number[]>(selected);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const removeFromMerge = (idUpload: number | string) => {
    const newSelected = [...currSelected.filter((s) => s !== idUpload)];
    setCurrSelected(newSelected);
    setSelected(newSelected);
  };

  const handleSubmit = async () => {
    if (!submit) return;
    setIsLoading(true);
    await submit();
    setIsLoading(false);
  };

  return (
    <div className="MergeUploadModal">
      <div className="modalName">{t("merge_files")}</div>
      <div className="formBody">
        <div className="upperBody">{t("merge_files_instructions")}</div>
        <div className="middelBody">
          {uploads
            .filter((u) => currSelected.includes(u.idUpload))
            .map((u, i) => {
              return (
                <div key={i} className="selectedUpload">
                  <div>{`${u.name}`}</div>
                  <div
                    className="deSelectUpload"
                    onClick={() => removeFromMerge(u.idUpload)}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="lowerBody">
          <Button onClick={cancel} color="primary">
            {t("cancel")}
          </Button>
          <Button
            loading={isLoading}
            disabled={currSelected.length <= 1}
            onClick={handleSubmit}
            variant="outlined"
          >
            {t("merge")}
          </Button>
        </div>
      </div>
    </div>
  );
};
