import { Job, User } from "../../index";

enum BonusType {
  PERCENTAGE = "PERCENTAGE",
  FIXED = "FIXED",
}
interface JobBonus {
  idJobBonus?: number;
  idJob: number;
  bonusType: BonusType;
  transcribe?: number;
  qc?: number;
  createdBy?: number;
  deletedAt?: Date;
  assignedBy?: User;
  job?: Job;
}

export type { JobBonus };
export { BonusType };
